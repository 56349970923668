import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import { createTask } from "../../store/actions/taskActions";
import { success, error } from "../../store/actions/toasterActions";
import { listTaskAdmin } from "../../store/actions/taskAdminActions";
import { list as listUnit } from "../../store/actions/unitActions";
import { list as listControlRule } from "../../store/actions/controlRuleActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import DateFnsUtils from "@date-io/date-fns"; // import
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

import moment from "moment"
import MomentUtils from "@date-io/moment"
import svLocale from "date-fns/locale/sv"

const localeMap = {
  sv: svLocale,
};

class CreateTaskContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locale: 'sv',
      name: {
        displayName: "Namn",
        value: null,
        required: true,
      },
      description: {
        displayName: "Beskrivning",
        value: null,
        required: true,
      },
      startDate: {
        displayName: "Start datum",
        value: null,
        required: true,
      },
      endDate: {
        displayName: "Stopp datum",
        value: null,
        required: true,
      },
      taskAdminId: {
        displayName: "Tilldelad användare",
        value: null,
        required: true,
      },
      unitId: {
        displayName: "Enhet",
        value: null,
        required: true,
      },
      controlRuleId: {
        displayName: "Kontrollpunkt",
        value: null,
        required: true,
      },
      controlRuleMin: {
        displayName: "Min värde",
        value: null,
        required: false,
      },
      controlRuleMax: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinCold: {
        displayName: "Min värde",
        value: null,
        required: false,
      },
      controlRuleMaxCold: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinHot: {
        displayName: "Min värde",
        value: null,
        required: false,
      },
      controlRuleMaxHot: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinFrozen: {
        displayName: "Max värde",
        value: null,
        required: false,
      },
      controlRuleMaxFrozen: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdFishFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdFishFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdHotFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdHotFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleFrozenIn: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleTwoHours: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleFourHours: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      daily: {
        displayName: "Daily",
        value: false,
        required: false,
      },
      weekly: {
        displayName: "Weekly",
        value: false,
        required: false,
      },
      monthly: {
        displayName: "Monthly",
        value: false,
        required: false,
      },
      quarter: {
        displayName: "Quarter",
        value: false,
        required: false,
      },
      eachtwomonths: {
        displayName: "EachTwoMonths",
        value: false,
        required: false,
      },
      monday:{
        displayName: "Monday",
        value: false,
        required: false,
      },
      tuesday:{
        displayName: "Tuesday",
        value: false,
        required: false,
      },
      wednesday:{
        displayName: "Wednesday",
        value: false,
        required: false,
      },
      thursday:{
        displayName: "Thursday",
        value: false,
        required: false,
      },
      friday:{
        displayName: "Friday",
        value: false,
        required: false,
      },
      saturday:{
        displayName: "Saturday",
        value: false,
        required: false,
      },
      sunday:{
        displayName: "Sunday",
        value: false,
        required: false,
      },
      repeatId:{
        displayName: "Notifikation frekvens",
        value: 0,
        required: true,
      },
      customRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      },
      monthRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      }      ,
      yearRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      } ,
      weakRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      },
      dayRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      },
      endRepetition:{
        displayName: "End repetition",
        value: 0,
        required: false
      }
      
    }

    this.handleCalStartChange = this.handleCalStartChange.bind(this)
    this.handleCalEndChange = this.handleCalEndChange.bind(this)

  }

  componentDidMount = async () => {
    this.props.listTaskAdmin();
    this.props.listUnit();
    this.props.listControlRule();
  };

  handleChange = (e) => {

    if (!e.target) return true;

    let currentField = this.state[e.target.name];
    if (currentField) {
      if (e.target.type === "checkbox") {
        currentField.value = e.target.checked;
      } else {
        currentField.value = e.target.value;
      }

      this.setState({
        [e.target.name]: currentField,
      })

    }
  };

  handleCalStartChange(e)  {
    this.setState({"startDate": e})   
  }

  handleCalEndChange(e)  {
    this.setState({"endDate": e})      
  }


  handleSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const request = this.validateInput();
    if (!_.isEmpty(request)) {
      this.props.createTask(request);
    }
  };

  validateInput = () => {
    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function (value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false;
      }  else if( _.includes(["daily", "weekly", "monthly", "quarter", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"], key) ||  !_.isEmpty(value.value)){
        request[key] = value.value;
      }
    })

    request["startDate"] = this.state.startDate
    request["endDate"]  = this.state.endDate
    request["endRepetition"]  = this.state.endRepetition.value
    // request["taskAdminName"]  = this.state.taskAdminId

    //console.log("requestrequestrequestrequestrequest", request)

    return isValid ? request : {};
  }

  getControlRulesInput(controlRuleId){
    if(
      controlRuleId === "vpsmWbFCtmKIAvDoIJZu" 
    || controlRuleId === "wFvh2AAmY3B2IvZY25AU" 
    || controlRuleId === "F0zFhYA1HrlIeVXz6wBs"
    || controlRuleId === "HWrn1AD66BmsteRtKIO3"
    || controlRuleId === "7n8E1hN17c5Cz15DELCd" 
    || controlRuleId === "O9BQfuhYxbPNqBkUgznX"     
    || controlRuleId === "KoeiG9iYHfjQnZHDl0st" 
    || controlRuleId === "PK5LoXdp7jOq9IykSU3b"
      ){
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Gränsvärde får ej överstiga (ange som -10 för minusgrader och 60 för plusgrader)"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={this.handleChange}
          defaultValue=""
        />
       )
    }

    if(
      controlRuleId === "fCNqBtAPGD3eJWOmELxP"     
    || controlRuleId === "6LwHq1LqNmxm3u5kdyPF"
    || controlRuleId === "DjqOZcYxxHWBl5F3O5cS"    
    || controlRuleId === "EW3Zr5ZwdypZ8xEILBHF"    
    || controlRuleId === "FtQZdnpPR8tWPKCp2rqz"
      ){
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Gränsvärde måste överstiga (ange som -10 för minusgrader och 60 för plusgrader)"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={this.handleChange}
          defaultValue=""
        />
       )
    }

    if(
      controlRuleId === "DMGRi5qiG80qHU3VQSeh"      
      ){
      return (<>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Isvatten måste understiga"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={this.handleChange}
          defaultValue=""
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMax"
          name="controlRuleMax"
          label="Kokande vatten måste överstiga"
          autoComplete="controlRuleMax"
          autoFocus
          onChange={this.handleChange}
          defaultValue=""
        />
       </>)
    }

    if(
      controlRuleId === "irdQkcnWcj1TaN3QUwnT"      
      ){
      return (<>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Gränsvärde, Temperaturkontroll 2 timmar (ange som -10)"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={this.handleChange}
          defaultValue=""
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMax"
          name="controlRuleMax"
          label="Gränsvärde, Temperaturkontroll 4 timmar (ange som -10)"
          autoComplete="controlRuleMax"
          autoFocus
          onChange={this.handleChange}
          defaultValue=""
        />
       </>)
    }
  }

  render() {
    const { classes } = this.props;
    const taskAdmins = this.props.taskAdmins ? this.props.taskAdmins.data : [];
    const units = this.props.unit ? this.props.unit.data : [];
    const controlRules = this.props.controlRule
      ? this.props.controlRule.data
      : [];
    //const { controlRuleId } = this.state;

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form
            className={classes.form}
            onSubmit={this.handleSubmit}
            style={{ marginTop: 0 }}
          >
            <h1>Skapa arbetsuppgift</h1>
            <p>
            Här skapar du dina arbetsuppgifter som du sedan delegerar till dina medarbetare, och kanske dig själv samt tidsbestämmer i vilket intervall arbetsuppgifterna ska genomföras.
<br></br>
Välj en kontrollpunkt samt vilken enhet som ska kontrolleras.
<br></br>
Användare är den person som skall utföra arbetsuppgiften. Du administrerar dina användare i fliken Användare.
<br></br>
På vissa kontrollpunkter ska du ange gränsvärde. Gränsvärde är det värde du vill att en temperatur skall under- eller överstiga om det ingår i kontrollpunkten.
<br></br>
Detaljer för uppgiften. 
Titel på uppgiften är den text som presenteras för användaren i menyn för dagens uppgift att utföra.
I beskrivningen skriver du en kort beskrivning, man kanske behöver anvisa var en viss enhet är lokaliserad.
<br></br><br></br>
Ange start- och slutdatum för arbetsuppgiften. I notifikation kan du välja om en uppgift ska sluta på datumet eller om uppgiften ska rulla på utan slutdatum.
<br></br><br></br>
Notifikation
Här väljer du i vilket intervall arbetsuppgiften skall utföras.
<br></br><br></br>
Skapa arbetsuppgift när du är klar.
<br></br>
Du kan i huvudmenyn redigera samt radera en arbetsuppgift.

            </p>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={4} spacing={3}>
                <h3>Välj kontrollpunkt</h3>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="controlRuleLabel">
                    Kontrollpunkter
                  </InputLabel>
                  <Select
                    variant="outlined"
                    id="controlRuleId"
                    name="controlRuleId"
                    labelId="ruleIdLabelId"
                    label="Custom Value"
                    labelWidth={80}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {controlRules.map((rule) => (
                      <MenuItem value={rule.id} key={rule.id}>
                        {rule.name}
                      </MenuItem>
                    ))}
                  </Select>


                  {this.getControlRulesInput(this.state.controlRuleId.value)}

                
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4}>
                <h3>Välj enhet att kontrollera</h3>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="unitLabel">Enheter</InputLabel>
                  <Select
                    variant="outlined"
                    id="unitId"
                    name="unitId"
                    labelId="unitLabel"
                    label="Custom Value"
                    labelWidth={80}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {units.map((unit) => (
                      <MenuItem value={unit.id} key={unit.id}>
                        {unit.name} - {unit.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4}>
                <h3>Tilldela en ansvarig</h3>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="countryLabel">Användare</InputLabel>
                  <Select
                    variant="outlined"
                    id="taskAdminId"
                    name="taskAdminId"
                    labelId="taskAdminLabel"
                    label="Tilldela en användare"
                    labelWidth={80}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {taskAdmins.map((user) => (
                      <MenuItem value={user.id} key={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

             
              </Grid>

              <Grid item xs={12} lg={6}>
                <h3>Detaljer för uppgiften</h3>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  name="name"
                  label="Titel på uppgiften"
                  autoComplete="name"
                  autoFocus
                  onChange={this.handleChange}
                  defaultValue=""
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  multiline={true}
                  name="description"
                  label="Beskrivning utav uppgiften - tryck enter för radbrytning/ny rad."
                  onChange={this.handleChange}
                />


               
              </Grid>


              <Grid item xs={12} lg={6}>
              <h3>Start och slutdatum för uppgiften</h3>
                <FormGroup>

                <p>Uppgiften startar</p>
                <MuiPickersUtilsProvider 
                libInstance={moment} 
                utils={DateFnsUtils} 
                locale={localeMap['sv']}>

                  <DatePicker id="startDate" 
                  invalidDateMessage=""
                  maxDateMessage="Datum för stor"
                  minDateMessage="Datum för liten"
                  disablePast={true}
                  format="yyyy MMM d"
                  name="startDate" value={this.state.startDate} 
                  onChange={this.handleCalStartChange} />

                </MuiPickersUtilsProvider>            

                <p>Uppgiften slutar</p>          
                <MuiPickersUtilsProvider 
                libInstance={moment} 
                utils={DateFnsUtils} 
                locale={localeMap['sv']}>

                  <DatePicker  id="endDate" invalidDateMessage=""
                  format="yyyy MMM d"
                  invalidDateMessage=""
                  maxDateMessage="Datum för stor"
                  minDateMessage="Datum för liten"
                  disablePast={false}
                  name="endDate" value={this.state.endDate} onChange={this.handleCalEndChange} />

                </MuiPickersUtilsProvider>
                
                



                </FormGroup>
              </Grid>


              <Grid item xs={12} lg={6}>

              <h4>Notifikation skickas ut</h4>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="unitLabel">Notifiera</InputLabel>
                  <Select
                    variant="outlined"
                    id="repeatId"
                    name="repeatId"
                    labelId="repeatLabel"
                    label="Repeteras"
                    labelWidth={80}
                    defaultValue=""
                    onChange={this.handleChange}
                    
                  >    
                    <MenuItem value={"1"} key={"1"}>Varje dag</MenuItem>
                    <MenuItem value={"2"} key={"2"}>Varje vecka ( på dagen för vald datum )</MenuItem>
                    <MenuItem value={"3"} key={"3"}>Varje månad ( på datumet varje månad )</MenuItem>
                    <MenuItem value={"4"} key={"4"}>Varje år ( på datumet varje månad och varje år )</MenuItem>
                    <MenuItem value={"5"} key={"5"}>Anpassad</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {this.state.repeatId.value === "5" && 
                  <>
                   <h4>Anpassad repetition</h4>
                   <Select
                    variant="outlined"
                    id="customRepeat"
                    name="customRepeat"
                    labelId="customRepeat"
                    label="customRepeat"
                    labelWidth={80}
                    defaultValue="0"
                    onChange={this.handleChange}
                  >    
                    <MenuItem value={"0"} key={"0"}>Välj frekvens</MenuItem>
                    {/* <MenuItem value={"1"} key={"1"}>Daglig</MenuItem> */}
                    <MenuItem value={"2"} key={"2"}>Veckovis</MenuItem>
                    {/* <MenuItem value={"3"} key={"3"}>Månadsvis</MenuItem>
                    <MenuItem value={"4"} key={"4"}>Årsvis</MenuItem> */}
                    ))}
                  </Select>

                    {this.state.customRepeat.value === "1" && 
                    <>
                    <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="dayRepeat"
                              multiline={true}
                              name="dayRepeat"
                              label="Varje X dag(ar)"
                              onChange={this.handleChange}
                            />

                    </>
                    }

                    {this.state.customRepeat.value === "2" && 
                    <>
                        <p>Ange för intervall. 1 varannan vecka, 2 var tredje vecka, 3 var fjärde vecka osv.</p>
                        <TextField
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="weakRepeat"
                                  multiline={true}
                                  name="weakRepeat"
                                  label="Varje X vecka(or)..."
                                  onChange={this.handleChange}
                                />
                        <p>på följande dagar</p>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.monday.value} onChange={this.handleChange} name="monday" />}
                            label="Måndag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.tuesday.value} onChange={this.handleChange} name="tuesday" />}
                            label="Tisdag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.wednesday.value} onChange={this.handleChange} name="wednesday" />}
                            label="Onsdag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.thursday.value} onChange={this.handleChange} name="thursday" />}
                            label="Torsdag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.friday.value} onChange={this.handleChange} name="friday" />}
                            label="Fredag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.saturday.value} onChange={this.handleChange} name="saturday" />}
                            label="Lördag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.sunday.value} onChange={this.handleChange} name="sunday" />}
                            label="Söndag"
                          />
               

                    </>
                    }

                  {this.state.customRepeat.value === "3" && 
                    <>
                    <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="monthRepeat"
                              multiline={true}
                              name="monthRepeat"
                              label="Varje X månad(er)"
                              onChange={this.handleChange}
                            />

                  
                    </>
                    }

                  {this.state.customRepeat.value === "4" && 
                    <>
                    <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="yearRepeat"
                              multiline={true}
                              name="yearRepeat"
                              label="Varje X år"
                              onChange={this.handleChange}
                            />

                  
                    </>
                    }



                  </>
                  }
              
              <h4>Stoppa repetition</h4>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="unitLabel">Stoppa notifikationen...</InputLabel>
                <Select
                    variant="outlined"
                    id="endRepetition"
                    name="endRepetition"
                    labelId="endRepetition"
                    label="Stoppa notifikationen..."
                    labelWidth={80}
                    defaultValue=""
                    onChange={this.handleChange}
                  >    
                    <MenuItem value={"0"} key={"0"}>Aldrig</MenuItem>
                    <MenuItem value={"1"} key={"1"}>På slut datumet</MenuItem>
                   

                  </Select>
              </FormControl>
              </Grid>

              <Grid item xs={12} lg={12}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  Skapa arbetsuppgift
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
    color: "black",
  },
});

const mapStateToProps = (state) => {
  return {
    taskAdmins: state.taskAdmin.list,
    unit: state.unit.list,
    controlRule: state.controlRule.list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createTask: (request) => dispatch(createTask(request, ownProps)),
    listTaskAdmin: () => dispatch(listTaskAdmin()),
    listUnit: () => dispatch(listUnit()),
    listControlRule: () => dispatch(listControlRule()),
    success: (request) => dispatch(success(request)),
    error: (request) => dispatch(error(request)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CreateTaskContainer))
);
