import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash/core";
import { createTaskAdmin } from "../../store/actions/taskAdminActions";
import { success, error } from "../../store/actions/toasterActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    paddingTop: '1px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    color: "black",
  },
})
class CreateTaskAdminContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: {
        displayName: "Name",
        value: null,
        required: true
      },
      email: {
        displayName: "Email",
        value: null,
        required: true
      },
      password: {
        displayName: "Password",
        value: null,
        required: true
      }
    };
  }

  handleChange = e => {
    let currentField = this.state[e.target.name];
    if (currentField) {
      currentField.value = e.target.value;

      this.setState({
        [e.target.name]: currentField
      });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    const request = this.validateInput();
    if (!_.isEmpty(request)) {
      this.props.createTaskAdmin(request);
      //this.props.history.push("/customer/list");
    }
  };

  validateInput = () => {
    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function(value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false;
      } else {
        request[key] = value.value;
      }
    });

    return isValid ? request : {};
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit} autoComplete="off">
            <h1>Skapa användare för din organisation</h1>
            <p>
            Registrera namn, email samt lösenord. <br></br>
Den som registreras hämtar appen EGNCTRL för IPhone eller för Android och loggar in med det användarnamn och lösenord du registrerat. <br></br>
Användaren kan enkelt byta lösenord genom att på inloggningsskärmen trycka på Glömt lösenord och får då en länk till sin email för att ange nytt lösenord.<br></br>

            </p>
           

            <Grid item xs={12} lg={6}>

            <h3>Detaljer</h3>

            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              label="För och efternamn"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={this.handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              onChange={this.handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="password"
              label="Lösenord"
              name="password"
              type="password"
              onChange={this.handleChange}
            />
            <Button
              type="submit"
              variant="contained"
             
              className={classes.submit}
            >
              Skapa
            </Button>
          </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createTaskAdmin: request => dispatch(createTaskAdmin(request, ownProps)),
    success: request => dispatch(success(request)),
    error: request => dispatch(error(request))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CreateTaskAdminContainer))
);
