const initialState = {
  list: {
    isLoading: false,
    data: [],
  },
  completedList: {
    isLoading: false,
    data: [],
  },
  badge: {
    reportCount: 0,
    count : 0
  },
};

const reportReducer = (state = initialState, action) => {

  switch (action.type) {

    case "FETCH_REPORTS_COMPLETED_SUCCESS":
      return {
        ...state,
        completedList: {
          data: action.payload,
          isLoading: false,
        },
      }

    case "FETCH_REPORTS_SUCCESS":
      return {
        ...state,
        list: {
          data: action.payload,
          isLoading: false,
        },
      }

    case "FETCH_REPORTS_COUNT_SUCCESS":
      return {
        ...state,
        badge: action.payload,
      }

    default:
      return state;
  }
}

export default reportReducer;
