import React, { Component } from "react";
import { connect } from "react-redux";
import { getById } from "../../store/actions/customerActions";
import EditCustomer from "./EditCustomer";
import _ from "lodash/core";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';


class EditCustomerContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    this.props.getById(id);
  };

  render() {
    const editCustomer = this.props.edit;
    if (_.isEmpty(editCustomer.data)) {
      return  <></>;
    }

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper style={classes.paper}>
            <EditCustomer customer={editCustomer.data}/>
        </Paper>
     </Grid>
    );
  }
}


const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: '1px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    color: "black",
  },
}

const mapStateToProps = state => {
  return {
    edit: state.customer.edit
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getById: request => dispatch(getById(request))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomerContainer);
