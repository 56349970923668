const initialState = {
  list: {
    isLoading: false,
    data: []
  },
  edit: {
    isLoading: false,
    data: {}
  }
};

const customerReducer = (state = initialState, action) => {
  //console.log("action==>" + JSON.stringify(action));
  switch (action.type) {
    case "FETCH_CUSTOMERS_BEGIN":
      return {
        ...state,
        list: {
          data: [],
          isLoading: true
        }
      };
    case "FETCH_CUSTOMERS_SUCCESS":
      return {
        ...state,
        list: {
          data: action.payload,
          isLoading: false
        }
      };
    case "FETCH_CUSTOMERS_FAILED":
      return {
        ...state,
        list: {
          data: [],
          isLoading: false
        }
      };
    case "CREATE_CUSTOMER_BEGIN":
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: true
        }
      };
      case "CREATE_CUSTOMER_SUCCESS":
      return {
        ...state,
        edit: {
          isLoading: false,
          date: {}
        }
      };
    case "CREATE_CUSTOMER_FAILED":
      return {
        ...state,
        edit: {
          ...state.edit,
          isLoading: false,
        }
      };
    case "FETCH_CUSTOMER_BEGIN":
      return {
        ...state,
        edit: {
          isLoading: true,
          data: {}
        }
      };
    case "FETCH_CUSTOMER_SUCCESS":
      return {
        ...state,
        edit: {
          isLoading: false,
          data: action.payload
        }
      };
    case "FETCH_CUSTOMER_FAILED":
      return {
        ...state,
        edit: {
          isLoading: false,
          data: {}
        }
      };

    default:
      return state;
  }
};

export default customerReducer;
