const initialState = {
  isLoading: false,
  user: {
    isLoaded: false,
    authenticated: false,
    displayName: null,
    email: null,
    role: null,
    customerId: null,
    customerName: null,
    licenseType: null
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGN_IN_BEGIN":
      return {
        ...state,
        isLoading: true,
      };
    case "SIGN_IN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        user: {
          isLoaded: true,
          authenticated: true,
          ...action.payload,
        },
      };

    case "SIGN_IN_FAILED":
      return {
        ...state,
        isLoading: false,
      };

    case "SIGN_OUT_SUCCESS":
      return {
        ...state,
        user: {
          isLoaded: true,
          authenticated: false,
          displayName: null,
          email: null,
          role: null,
          customerId: null,
          customerName: null,
        },
      };

    case "SIGN_OUT_FAILED":
      return {
        ...state,
      };
    case "UPDATE_USER_END":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
