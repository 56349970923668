const initialState = {
  list: {
    isLoading: false,
    data: []
  },
  edit: {
    isLoading: false,
    data: {}
  }
};

const unitReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_UNITS_SUCCESS":
      return {
        ...state,
        list: {
          data: action.payload,
          isLoading: false
        }
      };
    case "CREATE_UNIT_SUCCESS":
      return {
        ...state,
        list: {
          data: [],
          isLoading: false
        }
      };
    case "FETCH_UNIT_SUCCESS":
      return {
        ...state,
        edit: {
          data: action.payload,
          isLoading: false
        }
      };
    case "UPDATE_UNIT_SUCCESS":
      return {
        ...state,
        list: {
          data: [],
          isLoading: false
        },
        edit: {
          isLoading: false,
          data: {}
        }
      };
      case "DELETE_UNIT_SUCCESS":
        return {
          ...state,
          list: {
            data: [],
            isLoading: false
          },
          edit: {
            isLoading: false,
            data: {}
          }
        };

    default:
      return state;
  }
};

export default unitReducer;
