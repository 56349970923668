import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//https://browntreelabs.com/snackbars-in-react-redux-and-material-ui/
export default function Toaster() {
  const dispatch = useDispatch();

  const notification = useSelector(state => state.toaster.notification);

  //console.log("notification ==>" + notification);

  function handleClose() {
    //console.log("handleClose++");
    dispatch({ type: "NOTIFICATION_CLEAR" });
  }

  if (!notification) return;

  return (
    <Snackbar
      open={notification.type ? true : false}
      autoHideDuration={4000}
      onClose={handleClose}
        anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <Alert onClose={handleClose} severity={notification.type}>
        {notification.message}
      </Alert>
    </Snackbar>
    // <Snackbar
    //   anchorOrigin={{
    //     vertical: "bottom",
    //     horizontal: "left"
    //   }}
    //   open={notification.type ? true : false}
    //   autoHideDuration={4000}
    //   onClose={handleClose}
    //   aria-describedby="client-snackbar"
    //   message={
    //     <span id="client-snackbar">
    //       <Icon>check_circle</Icon>
    //       {notification.message ? notification.message : ""}
    //     </span>
    //   }
    //   action={[
    //     <IconButton
    //       key="close"
    //       aria-label="close"
    //       color="inherit"
    //       onClick={handleClose}
    //     >
    //       <Icon>close</Icon>
    //     </IconButton>
    //   ]}
    // />
  );
}
