import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash/core";
import { create as createNotificationSettings } from "../../store/actions/notificationSettingsActions";
import { success, error } from "../../store/actions/toasterActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import moment from "moment"
import MomentUtils from "@date-io/moment"
import svLocale from "date-fns/locale/sv"
import DateFnsUtils from "@date-io/date-fns"; // import
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

const localeMap = {
  sv: svLocale,
};

class CreateNotificationSettingsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
    
      name: {
        displayName: "Namn",
        value: null,
        required: true
      },

      fromDate: {
        displayName: "Från datum",
        value: null,
        required: true
      },

      toDate: {
        displayName: "Till datum",
        value: null,
        required: true
      }
    }

    this.handleCalStartChange = this.handleCalStartChange.bind(this)
    this.handleCalEndChange = this.handleCalEndChange.bind(this)

  }


  handleCalStartChange(e)  {
    console.log("eeee",e)
    this.setState({
      "fromDate": e,
    })   

    console.log("this.statte")
  }

  handleCalEndChange(e)  {

    this.setState({
      "toDate": e,
    })   
   
  }


  handleChange = e => {
    // let currentField = this.state[e.target.name];
    // if (currentField) {
    //   currentField.value = e.target.value;

    //   this.setState({
    //     [e.target.name]: currentField
    //   });
    // }
    // if (!e.target) return true;

    let currentField = this.state[e.target.name];
    if (currentField) {
      if (e.target.type === "checkbox") {
        currentField.value = e.target.checked;
      } else {
        currentField.value = e.target.value;
      }

      this.setState({
        [e.target.name]: currentField,
      })

    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    const request = this.validateInput();
    if (!_.isEmpty(request)) {
      this.props.createNotificationSettings(request);
    }
  };

  validateInput = () => {
    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function(value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false;
      } else {
        console.log("key", key)
        console.log("value", value)
        if(key == "name"){
          request[key] = value.value;
        }else{
          request[key] = value;
        }
        
      }
    });

    if (this.props.auth.user.customerId) {
      request["customerId"] = this.props.auth.user.customerId;
    } else {
      isValid = false;
      _this.props.error(`Customer Id är obligatorisk`);
    }

    return isValid ? request : {};
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
              
                      <h1>Skapa kalenderavvikelse</h1>
                      <p>
                      Ge din avvikelse ett namn som t.ex. Sommarstängt. Ange inom det två datum plus/minus en dag som du vill att din kalenderavvikelse ska gälla.
<br></br><br></br>
Exempel: Du har stängt den 6 juli till den 6 augusti. Du anger då startdatum den 5 juli och slutdatum den 7 augusti. Om julledigheten börjar den 23/12 anger du startdatum den 22/12.
<br></br><br></br>
Tips. Övriga dagar som du kanske har stängt mellandagarna mellan jul och nyår. Kristi Himmelfärdsdagen, Nationaldagen etc.

                      </p>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                    
                        <h3>Detaljer</h3>

                        <TextField
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="name"
                          name="name"
                          label="Namn"
                          autoComplete="name"
                          autoFocus
                          onChange={this.handleChange}
                        />

                    </Grid>
                    <Grid item xs={12} lg={4}>

                        <h3>Från datum:</h3>
                        <MuiPickersUtilsProvider 
                        libInstance={moment} 
                        utils={DateFnsUtils} 
                        locale={localeMap['sv']}>

                          <DatePicker id="fromDate" 
                           invalidDateMessage=""
                          disablePast={false}
                          format="yyyy-MMM-d"
                          name="fromDate" value={this.state.fromDate} 
                          onChange={this.handleCalStartChange} />

                        </MuiPickersUtilsProvider>            

                    </Grid>
                
                    <Grid item xs={12} lg={4}>
                      
                      <h3>Till datum:</h3>
                      <MuiPickersUtilsProvider 
                        libInstance={moment} 
                        utils={DateFnsUtils} 
                        locale={localeMap['sv']}>

                          <DatePicker id="toDate" 
                           invalidDateMessage=""
                          disablePast={false}
                          format="yyyy-MMM-d"
                          name="toDate" value={this.state.toDate} 
                          onChange={this.handleCalEndChange} />

                        </MuiPickersUtilsProvider>   

                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Button
                          type="submit"
                          variant="contained"                       
                          className={classes.submit}
                        >
                          Skapa
                        </Button>
                    
                    </Grid>
                </Grid>
            </form>
         </Paper>
      </Grid>
    )
  }
}

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    paddingTop: '1px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    color: "black",
  },
});

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createNotificationSettings: request =>
      dispatch(createNotificationSettings(request, ownProps)),
    success: request => dispatch(success(request)),
    error: request => dispatch(error(request))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CreateNotificationSettingsContainer))
);
