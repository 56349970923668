const initialState = {
  list: {
    isLoading: false,
    data: []
  },
  edit: {
    isLoading: false,
    data: {}
  }
};

const taskAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CREATE_TASK-ADMIN_FAILED":
      return {
        ...state,
        edit: {
          isLoading: false,
          data: {}
        }
      };

    case "FETCH_TASK-ADMINS_SUCCESS":
      return {
        ...state,
        list: {
          data: action.payload,
          isLoading: false
        }
      };
    case "FETCH_TASK-ADMINS_FAILED":
      return {
        ...state,
          list: {
            data: [],
            isLoading: false
          }
      };
    default:
      return state;
  }
};

export default taskAdminReducer;
