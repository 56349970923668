import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash/core";
import { getById } from "../../store/actions/unitActions";
import { success, error } from "../../store/actions/toasterActions";
import EditUnit from "./EditUnit";

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class EditUnitContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    await this.props.getById(id);
  };

  render() {
    const unit = this.props.edit;
    console.log("unit.data", unit.data);
    if (_.isEmpty(unit.data)) {
      return <></>;
    }

    return (
      <EditUnit unit={unit.data} />
    )
  }
}

const mapStateToProps = state => {
  return {
    edit: state.unit.edit
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getById: id => dispatch(getById(id, ownProps)),
    success: request => dispatch(success(request)),
    error: request => dispatch(error(request))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EditUnitContainer))
);
