const initialState = {
  list: {
    isLoading: false,
    data: []
  },
  edit: {
    isLoading: false,
    data: {}
  }
};

const controlRuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CONTROL-RULES_SUCCESS":
      return {
        ...state,
        list: {
          data: action.payload,
          isLoading: false
        }
      };
    case "CREATE_CONTROL-RULE_SUCCESS":
      return {
        ...state,
        list: {
          data: [],
          isLoading: false
        }
      };
    case "FETCH_CONTROL-RULE_SUCCESS":
      return {
        ...state,
        edit: {
          data: action.payload,
          isLoading: false
        }
      };
      case "REMOVE_CONTROL-RULE_SUCCESS":
      return {
        list: {
          isLoading: false,
          data: []
        },
        edit: {
          isLoading: false,
          data: {}
        }
      };
    default:
      return state;
  }
};

export default controlRuleReducer;
