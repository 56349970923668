import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { update } from "../../store/actions/customerActions";
import { getCountryList, getRegionList } from "../../helpers/masterDataHelper";
import { success, error } from "../../store/actions/toasterActions";
import _ from "lodash/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Spinner } from 'react-activity';

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class EditCustomer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: {
        displayName: "Company Name",
        value: this.props.customer.name,
        required: true,
      },
      address: {
        displayName: "Address",
        value: this.props.customer.address,
        required: true,
      },
      zip: {
        displayName: "Zip",
        value: this.props.customer.zip,
        required: true,
      },
      // country: {
      //   displayName: "Country",
      //   value: this.props.customer.country,
      //   required: true,
      // },
      // city: {
      //   displayName: "City",
      //   value: this.props.customer.city,
      //   required: true,
      // },
      organizationalNumber: {
        displayName: "Organizational Number",
        value: this.props.customer.organizationalNumber,
        required: true,
      },
      subscriptionValidUntil: {
        displayName: "Subscription Valid Until",
        value: this.props.customer.subscriptionValidUntil,
        required: true,
      },
      licenseType: {
        displayName: "Licens typ",
        value: this.props.customer.licenseType,
        required: true,
      },
      namecontact:{
        displayName: "Kontakt namn",
        value: this.props.customer.namecontact,
        required: true,
      },
      altemail:{
        displayName: "Email för rapportering",
        value: null,
        required: true,
      },
      
      tel:{
        displayName: "Kontakt tel",
        value: this.props.customer.tel,
        required: true,
      },
      // county:{
      //   displayName: "Ort",
      //   value: this.props.customer.county,
      //   required: false
      // },
      customerNumber:{
        displayName: "Kundnummer",
        value: this.props.customer.customerNumber ? this.props.customer.customerNumber : "",
        required: false
      }
      ,
      restaurantName:{
        displayName: "Namn på verksamheten",
        value: this.props.customer.restaurantName ? this.props.customer.restaurantName : "",
        required: false
      },
      restaurantZip:{
        displayName: "Postadress",
        value: this.props.customer.restaurantZip ? this.props.customer.restaurantZip : "",
        required: false
      },
      restaurantZipNumber:{
        displayName: "Postnummer/Ort",
        value: this.props.customer.restaurantZipNumber ? this.props.customer.restaurantZipNumber : "",
        required: false
      },
      restaurantTel:{
        displayName: "Telefon",
        value: this.props.customer.restaurantTel ? this.props.customer.restaurantTel : "",
        required: false
      },
      customerType:{
        displayName: "Typ av kund",
        value: this.props.customer.customerType ? this.props.customer.customerType : "",
        required: false
      }
    };

    this.countryList = [];
    this.regionList = [];
    this.licenseType = [
      { key: "class1", value: "1-3 användare" },
      { key: "class2", value: "4-6 användare" },
      { key: "class3", value: "7-10 användare" },
      { key: "class4", value: "11-20 användare" },
    ];
  }

  componentDidMount = async () => {
    this.countryList = getCountryList();
    this.regionList = getRegionList(this.props.customer.country);
    //Dirty call to bind country and city
    this.setState(this.state);
  };

  handleChange = (e) => {
    let currentField = this.state[e.target.name];
    if (currentField) {
      currentField.value = e.target.value;

      if (e.target.name === "country") {
        this.regionList = getRegionList(e.target.value);
      }

      this.setState({
        [e.target.name]: currentField,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const request = this.validateInput();

    //console.log("request==>" + JSON.stringify(request));
    if (!_.isEmpty(request)) {
      this.props.update(request);
    }
  };

  validateInput = () => {
    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function (value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false;
      } else {
        request[key] = value.value;
      }
    });

    return isValid ? request : {};
  };

  render() {
    const { classes } = this.props;
    const customer = this.props.customer;
    //this.countryList = getCountryList();
    //const countryList = getCountryList();
    //this.regionList = getRegionList(this.props.customer.country);
    //console.log("render");
    return (
      <>
        <h1>Editera kund: {customer.name}</h1>

        <form className={classes.form} onSubmit={this.handleSubmit}>

        <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Företags namn"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  onChange={this.handleChange}
                  defaultValue={customer.name ? customer.name : ""}
                />
                {/* <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  onChange={this.handleChange}

                /> */}
                {/* <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  label="Lösenord"
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                /> */}
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="address"
                  name="address"
                  label="Address"
                  onChange={this.handleChange}
                  defaultValue={customer.address ? customer.address : ""}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="zip"
                  name="zip"
                  label="Postnummer"
                  onChange={this.handleChange}
                  defaultValue={customer.zip ? customer.zip : ""}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="county"
                  name="county"
                  label="Ort"
                  onChange={this.handleChange}
                  defaultValue={customer.county ? customer.county : ""}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="customerNumber"
                  name="customerNumber"
                  label="Kundnummer"
                  onChange={this.handleChange}
                  defaultValue={customer.customerNumber ? null : ""}
                />

              </Grid>

              <Grid item xs={6}>
                
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="namecontact"
                  name="namecontact"
                  label="Namn kontaktperson"
                  onChange={this.handleChange}
                  defaultValue={customer.namecontact ? customer.namecontact : ""}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="altemail"
                  name="altemail"
                  label="Email för rapportering"
                  onChange={this.handleChange}
                  defaultValue={customer.altemail ? customer.altemail : ""}
                />              

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="tel"
                  name="tel"
                  label="Telefon nummer"
                  onChange={this.handleChange}
                  defaultValue={customer.tel ? customer.tel : ""}
                />

                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="countryLabel">Land</InputLabel>
                  <Select
                    variant="outlined"
                    id="country"
                    name="country"
                    labelId="countryLabel"
                    label="Land"
                    labelWidth={55}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {countryList.map((country) => (
                      <MenuItem value={country.name} key={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="cityLabel">Stad</InputLabel>
                  <Select
                    variant="outlined"
                    id="city"
                    name="city"
                    labelId="cityLabel"
                    label="city"
                    labelWidth={30}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {this.regionList.map((region) => (
                      <MenuItem value={region.name} key={region.name}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="organizationalNumber"
                  name="organizationalNumber"
                  label="Org. nummer"
                  onChange={this.handleChange}
                  defaultValue={customer.organizationalNumber ? customer.organizationalNumber : ""}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="subscriptionValidUntil"
                  name="subscriptionValidUntil"
                  label="Abonnemnag till och med"
                  autoComplete="subscriptionValidUntil"
                  type="date"
                  defaultValue={customer.subscriptionValidUntil}
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id=" licenseTypeLabel">Licens typ</InputLabel>
                  <Select
                    variant="outlined"
                    id="licenseType"
                    name="licenseType"
                    labelId="licenseTypeLabel"
                    label="licenseTypeLabel"
                    labelWidth={70}
            
                    onChange={this.handleChange}
                    defaultValue={customer.licenseType}
                  >
                    {this.licenseType.map((type) => (
                      <MenuItem value={type.key} key={type.key}>
                        {type.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>


                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantName"
                  name="restaurantName"
                  label="Namn på verksamheten"
                  onChange={this.handleChange}
                  defaultValue={customer.restaurantName ? customer.restaurantName : ""}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantZip"
                  name="restaurantZip"
                  label="Postadress"
                  onChange={this.handleChange}
                  defaultValue={customer.restaurantZip ? customer.restaurantZip : ""}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantZipNumber"
                  name="restaurantZipNumber"
                  label="Postnummer/Ort"
                  onChange={this.handleChange}
                  defaultValue={customer.restaurantZipNumber ? customer.restaurantZipNumber : ""}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantTel"
                  name="restaurantTel"
                  label="Tel."
                  onChange={this.handleChange}
                  defaultValue={customer.restaurantTel ? customer.restaurantTel : ""}
                />    

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id=" licenseTypeLabel">Typ av kund</InputLabel>
                  <Select
                    variant="outlined"
                    id="customerType"
                    name="customerType"
                    labelId="customerTypeLabel"
                    label="customerTypeLabel"
                    labelWidth={70}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
               
                      <MenuItem value={'0'} key={'0'}>
                        Restaurang
                      </MenuItem>
                      <MenuItem value={'1'} key={'1'}>
                        Slakteri
                      </MenuItem>
                  </Select>
                </FormControl>

                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  Spara kund
                
                </Button>
              </Grid>
            </Grid>

          {/* <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Company Name"
            name="name"
            autoComplete="name"
            autoFocus
            onChange={this.handleChange}
            defaultValue={customer.name}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="address"
            name="address"
            label="address"
            onChange={this.handleChange}
            defaultValue={customer.address}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="zip"
            name="zip"
            label="zip"
            onChange={this.handleChange}
            defaultValue={customer.zip}
          />

          <TextField
           defaultValue={customer.county}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="county"
                  name="county"
                  label="Ort"
                  onChange={this.handleChange}
                />

                <TextField
                defaultValue={customer.customerNumber}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="customerNumber"
                  name="customerNumber"
                  label="Kundnummer"
                  onChange={this.handleChange}
                /> */}

          {/* <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="countryLabel">Country</InputLabel>
            <Select
              variant="outlined"
              id="country"
              name="country"
              labelId="countryLabel"
              label="country"
              labelWidth={55}
              defaultValue={customer.country}
              onChange={this.handleChange}
            >
              {this.countryList.map((country) => (
                <MenuItem value={country.name} key={country.name}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="cityLabel">City</InputLabel>
            <Select
              variant="outlined"
              id="city"
              name="city"
              labelId="cityLabel"
              label="city"
              labelWidth={30}
              defaultValue={customer.city}
              onChange={this.handleChange}
            >
              {this.regionList.map((region) => (
                <MenuItem value={region.name} key={region.name}>
                  {region.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="organizationalNumber"
            name="organizationalNumber"
            label="organizational Number"
            onChange={this.handleChange}
            defaultValue={customer.organizationalNumber}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="subscriptionValidUntil"
            name="subscriptionValidUntil"
            label="subscriptionValidUntil"
            autoComplete="subscriptionValidUntil"
            type="date"
            onChange={this.handleChange}
            defaultValue={customer.subscriptionValidUntil}
          />

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="licenseType">licens typ</InputLabel>
            <Select
              variant="outlined"
              id="licenseType"
              name="licenseType"
              labelId="licenseType"
              label="licenseType"
              labelWidth={70}
              onChange={this.handleChange}
              defaultValue={customer.licenseType}
            >
              {this.licenseType.map((type) => (
                <MenuItem value={type.key} key={type.key}>
                  {type.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Save
          </Button> */}
        </form>
      </>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//     auth: state.firebase.auth,
//     edit: state.customer.edit
//   };
// };

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    update: (request) => dispatch(update(request, ownProps)),
    success: (request) => dispatch(success(request)),
    error: (request) => dispatch(error(request)),
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(EditCustomer))
);
