import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reduxFirestore, getFirestore } from "redux-firestore";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import rootReducer from "./reducers/rootReducer";
import firebaseConfig from "../config/firebaseConfig";
import * as Actions from './actions/authActions';

let composeEnhancers;
if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

//http://extension.remotedev.io/#usage
const store = createStore(
  rootReducer,
  //compose(
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reactReduxFirebase(firebaseConfig, {
      userProfile: "users",
      useFirestoreForProfile: true,
      attachAuthIsReady: true
    }),
    reduxFirestore(firebaseConfig) // redux bindings for firestore
  )
);

store.dispatch(Actions.verifyAuth());

export default store;
