import React, { Component } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import _ from "lodash/core"
import { update as updateUnit } from "../../store/actions/unitActions"
import { success, error } from "../../store/actions/toasterActions"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { Link } from "react-router-dom"

class EditUnit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: {
        displayName: "Namn",
        value: this.props.unit.name,
        required: false
      },
      description: {
        displayName: "Beskrivning",
        value: this.props.unit.description,
        required: false
      }
    }
  }

  handleChange = e => {
    let currentField = this.state[e.target.name]
    if (currentField) {
      currentField.value = e.target.value

      this.setState({
        [e.target.name]: currentField
      })
    }
  }

  handleSubmit = async event => {
    event.preventDefault()
    const request = this.validateInput()
    if (!_.isEmpty(request)) {
      this.props.updateUnit(request)
    }
  }

  validateInput = () => {
    let isValid = true
    const _this = this
    let request = {}
    _.forEach(this.state, function(value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false
        _this.props.error(`${value.displayName} är obligatorisk`)
        return false
      } else {
        request[key] = value.value
      }
    })

    return isValid ? request : {}
  };

  render() {

    const { classes } = this.props;
    const unit = this.props.unit;

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
           
                  <h1>Uppdatera enhet</h1>
                  <p>
                  Här kan du uppdatera text. Ändra namn samt beskrivning på en enhet.

                  </p>
                 
             
              <Grid item xs={12} lg={6}>
                
                  <h3>Detaljer</h3>

                  <TextField
                   
                    key={unit.name}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    name="name"
                    label="Namn"
                    autoComplete="name"
                    autoFocus
                    onChange={this.handleChange}
                    defaultValue={unit.name}
                  />

                  <TextField
                    key={unit.description}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="description"
                    multiline={true}
                    name="description"
                    label="Beskrivning"
                    onChange={this.handleChange}
                    defaultValue={unit.description}
                  />

                  {/* <Button
                        component={Link} variant="contained" to={"/unit/list"}                         
                        className={classes.submit}
                      >
                        Avbryt
                      </Button>
                      &nbsp;&nbsp;&nbsp; */}

                  <Button
                    type="submit"
                    variant="contained"                  
                    className={classes.submit}
                  >
                    Uppdatera
                  </Button>
                
              </Grid>
      
          </form>
        </Paper>
      </Grid>
    )
  }
}

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    paddingTop: '1px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    color: "black",
  },
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUnit: request => dispatch(updateUnit(request, ownProps)),
    success: request => dispatch(success(request)),
    error: request => dispatch(error(request))
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(EditUnit))
);
