import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { list as listNotificationSettings } from "../../store/actions/notificationSettingsActions";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DialogDefault from "../Shared/dialogDefault";
import Moment from "react-moment";

const ListNotificationSettingsContainer = (props) => {
  useEffect(() => {
    props.listNotificationSettings();
  }, []);

  const notificationSettings = props.notificationSettings
    ? props.notificationSettings.list.data
    : [];

  const [dialog, setDialog] = useState({ open: false, id: null });

  const handleClickCancel = () => {
    setDialog({
      open: false,
      id: null,
    });
  };

  const toDateTime = (secs) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  return (
    <Grid item xs={12} md={12} lg={12}>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
        <h1>Kalenderavvikelse</h1>
        </Grid>
        <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px 0'}}>
        <Button
          variant="contained"
          component={Link}
          to="/notificationSettings/create"
        >
          <i className="gg-add"></i> Skapa kalenderavvikelse
        </Button>
        </Grid>
      </Grid>

      <Paper style={classes.paper}>
        
        <p>
        I kalendern kan du lägga in perioder då du inte vill att dina användare ska aviseras om arbetsuppgifter. Om du har stängt t.ex. under sommaren lägger du in det här. 
        </p>

        

        <TableContainer style={{ padding: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Namn
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Från datum
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Till datum                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationSettings.map(row => {
                //console.log("row.fromDate==> ", row.fromDate);
                return <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>
                  
                  <Moment locale="sv" format={"MMMM D YYYY"}>
                    {toDateTime(row.fromDate.seconds)}
                  </Moment>

                  </TableCell>
                  <TableCell> <Moment locale="sv"  format={"MMMM D YYYY"}>
                        
                        {toDateTime(row.toDate.seconds)}
                      </Moment></TableCell>
                  <TableCell>
                    <Button size="small"      
                      variant="contained"
                      className={classes.submit}
                      to={"/notificationSettings/edit/" + row.id}
                      component={Link}
                    >
                      Redigera
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" size="small"      
                      onClick={() => {
                        setDialog({ open: true, id: row.id });
                      }}
                    >Radera
                    </Button>
                  </TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <DialogDefault
        module="notificationSettings"
        action="delete"
        open={dialog.open}
        id={dialog.id}
        title={"Kalender avvikelse"}
        message={"Vill du verkligen radera?"}
        handleClickCancel={handleClickCancel}
      />
    </Grid>
  );
};

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
};

const mapStateToProps = (state) => {
  return {
    notificationSettings: state.notificationSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listNotificationSettings: () => dispatch(listNotificationSettings()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListNotificationSettingsContainer);
