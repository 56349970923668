import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { list, disableCustomer, deleteCustomer } from "../../store/actions/customerActions";
import TablePagination from "@material-ui/core/TablePagination";
import DialogDefault from "../Shared/dialogDefault"

const ListCustomerContainer = (props) => {
  const classes = useStyles();

  //const [customers, setCustomers] = useState([])
  useEffect(() => {
    async function asyncGetCustomerFunction() {
      await props.list({});
      //const response = await props.list({});
      //setCustomers( response ? response : []);
    }
    asyncGetCustomerFunction();
  }, []);

  const [dialog, setDialog] = useState({ open: false, id: null })
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const customers = props.customer ? props.customer.list.data : [];
  //console.log("customers", props.customer)

  const handleClickCancel = () => {
    setDialog({
      open: false,
      id: null
    });
  }

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper className={classes.paper}>
        <h1>Befintliga kunder</h1>
        <Button variant="contained" component={Link} to="/customer/create">
          <i className="gg-add"></i> Skapa kund
        </Button>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Namn</TableCell>
              <TableCell>Stad</TableCell>
              <TableCell>Land</TableCell>
              <TableCell>Org. nummer</TableCell>
              <TableCell>Abonnemang</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>{row.organizationalNumber}</TableCell>
                  <TableCell>{row.subscriptionValidUntil}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      component={Link}
                      to={"/customer/edit/" + row.id}
                    >
                      Redigera
                    </Button>

                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => {
                        props.disableCustomer({
                          id: row.id,
                          disabled: !row.disabled,
                        });
                      }}
                    >
                      {row.disabled ? "Ej Aktiv" : "Aktiv"}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"

                      onClick={() => {
                        setDialog({open:true, id: row.id });
                      }}

                      // onClick={() => {
                      //   props.deleteCustomer({
                      //     id: row.id,
                      //     deleted: true,
                      //   });
                      // }}
                    >
                      {"Radera"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={customers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <DialogDefault
        module="customerList"
        action="delete"
        open={dialog.open}
        id={dialog.id}
        title={"Kund"}
        message={"Vill du verkligen radera?"}
        handleClickCancel={handleClickCancel}
      />

    </Grid>
    
  )

  
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
});

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    list: (request) => dispatch(list(request)),
    disableCustomer: (request) => dispatch(disableCustomer(request, ownProps)),
    deleteCustomer: (request) => dispatch(deleteCustomer(request, ownProps)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListCustomerContainer);
