const initialState = {
  list: {
    isLoading: false,
    data: []
  },
  edit: {
    isLoading: false,
    data: {}
  }
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case "FETCH_TASKS_SUCCESS":
      return {
        ...state,
          list: {
            isLoading: false,
            data: action.payload
          }
      };

    case "FETCH_TASK_SUCCESS":
      return {
        ...state,
          edit: {
            isLoading: false,
            data: action.payload
          }
      };

      case "UPDATE_TASK_SUCCESS":
      return {
        ...state,
          edit: {
            isLoading: false,
            data: {}
          }
      };

      case "REMOVE_TASK_SUCCESS":
      return {
        list: {
          isLoading: false,
          data: []
        },
        edit: {
          isLoading: false,
          data: {}
        }
      };


    default:
      return state;
  }
};

export default taskReducer;
