import { updateUserStatus, deleteUser } from "./authActions";
import sortJsonArray from "sort-json-array";

export const createTaskAdmin = (request, ownProps) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const auth = getState().auth;

    //Check license Type
    //let canCreateNewUser = false;
    let licenseCount = 0;
    if (auth.user.licenseType === "class1") {
      licenseCount = 3;
    } else if (auth.user.licenseType === "class2") {
      licenseCount = 6;
    } else if (auth.user.licenseType === "class3") {
      licenseCount = 10;
    } else if (auth.user.licenseType === "class4") {
      licenseCount = 20;
    }
    
    //Get existing count of users.
    const existingTaskAdmins = await firestore
      .collection("taskAdmins")
      .where("customerId", "==", auth.user.customerId)
      .get();

    if (existingTaskAdmins.size >= licenseCount) {
      dispatch({
        type: "NOTIFICATION_FAILED",
        payload: "Du har nått gränsen för användaren i din licens, kontakt EGNCTRL för hjälp.",
      });
      return;
    }

    var createUser = firebase.functions().httpsCallable("createUser");

    const createUserRequest = {
      email: request.email,
      password: request.password,
      role: "TaskAdmin",
      displayName: request.name,
      customerId: auth.user.customerId
    };

    createUser(createUserRequest)
      .then(function (response) {

        const user = response.data;
        delete request["password"];
        console.log("request==> " + JSON.stringify(request))

        firestore
          .collection("users")
          .doc(user.uid)
          .set({
            ...request,
            customerId: auth.user.customerId,
            createdOn: new Date(),
            createdBy: auth.user.uid,
            displayName: request.name,
            role: "TaskAdmin",
          });


        return firestore
          .collection("taskAdmins")
          .doc(user.uid)
          .set({
            ...request,
            customerId: auth.user.customerId,
            createdOn: new Date(),
            createdBy: auth.user.uid,
          });
      })
      .then(function (response) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Användaren skapad!",
        });
        ownProps.history.push("/taskAdmin/list");
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Användaren skapades ej!",
        });
        dispatch({ type: "CREATE_TASK-ADMIN_FAILED" });
      });
  };
};

export const listTaskAdmin = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;
    dispatch({ type: "FETCH_TASK-ADMINS_BEGIN", data: [] });

    firestore
      .collection("taskAdmins")
      .where("customerId", "==", auth.user.customerId)
      .get()
      .then(function (querySnapshot) {
        
        let taskAdmins = [];

        querySnapshot.forEach(function (doc) {
          taskAdmins.push({
            id: doc.id,
            ...doc.data(),
          })
        });

        sortJsonArray(taskAdmins, "name");

        dispatch({
          type: "FETCH_TASK-ADMINS_SUCCESS",
          payload: taskAdmins,
        })

      })
      .catch(function (error) {
        console.log("Error getting task admins: ", error);
        dispatch({ type: "FETCH_TASK-ADMINS_FAILED", data: [] });
      });
  };
};

export const disableTaskAdmin = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "TASK-ADMIN_BEGIN" });
    const firestore = getFirestore();
    firestore
      .collection("taskAdmins")
      .doc(request.id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let taskAdmin = doc.data();
          taskAdmin.disabled = request.disabled;

          firestore
            .collection("taskAdmins")
            .doc(doc.id)
            .update(taskAdmin)
            .then(function (doc) {
              const disableUser = {
                id: request.id,
                disabled: request.disabled,
              };
              //Now disable user associated
              dispatch(updateUserStatus(disableUser));

              dispatch({
                type: "NOTIFICATION_SUCCESS",
                payload: "Kund uppdaterad.",
              });
              dispatch(listTaskAdmin());
              //ownProps.history.push("/customer/list");
            });
        } else {
          //dispatch({ type: "FETCH_CUSTOMER_FAILED", payload: {} });
        }
      })
      .catch(function (error) {
        console.log("Error disable customer: ", error);
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
      });
  };
};

export const deleteTaskAdmin = (uid, ownProps) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "TASK-ADMIN_BEGIN" });
    const firebase = getFirebase();
    const firestore = getFirestore();
    
    try{

      //Delete user
      var response= await dispatch(await deleteUser({'uid' : uid}));

      //delete user table
      await firestore
            .collection("users")
            .doc(uid)
            .delete();

      //delete task admin
      await firestore
            .collection("taskAdmins")
            .doc(uid)
            .delete();

      dispatch({
        type: "NOTIFICATION_SUCCESS",
        payload: "Uppgiftsadmin har tagits bort.",
      });

      dispatch(listTaskAdmin());

    }catch(err){
      console.log(err);
      dispatch({ type: "NOTIFICATION_FAILED", payload: "Användarens radering misslyckades!"});
    }
  };
};

