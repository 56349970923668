import React, { Component } from 'react';
import { connect } from "react-redux";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";

import { getBadgeCountByCustomer } from "../../store/actions/reportsActions";

class BellContainer extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        await this.props.getBadgeCountByCustomer(this.props.auth.user.customerId)
    };

    render() {
        return <IconButton color="inherit">
            <Badge badgeContent={this.props.report.badge.count} color="secondary">
                <NotificationsIcon />
            </Badge>
        </IconButton>;
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        report: state.report,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getBadgeCountByCustomer: (customerId) => dispatch(getBadgeCountByCustomer(customerId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BellContainer);


