import { updateUserStatus } from "./authActions";

export const create = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const auth = getState().firebase.auth;
    dispatch({
      type: "CREATE_CUSTOMER_BEGIN",
    });

    var createUser = firebase.functions().httpsCallable("createUser");
    const createUserRequest = {
      email: request.email,
      password: request.password,
      role: "CustomerAdmin",
      displayName: request.name
    };

    createUser(createUserRequest)
      .then(function(response) {
        const user = response.data;
        delete request["password"];
        console.log("request==> " + JSON.stringify(request));

        return firestore.collection("customers").add({
          ...request,
          createdOn: new Date(),
          createdBy: auth.uid,
          subscriptionValidUntil: request.subscriptionValidUntil,
          disabled: false,
          deleted:false,
          users: [user.uid]
        });
      })
      .then(function(response) {
        dispatch({ type: "CREATE_CUSTOMER_SUCCESS" });
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Kund skapad!"
        });
        
        ownProps.history.push("/customer/list");
      })
      .catch(error => {
        console.log("errors==> " + error.message);
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Kund skapad ej!"
        });
        dispatch({ type: "CREATE_CUSTOMER_FAILED" });
      });
  };
};

export const list = () => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  return new Promise(resolve => {
    const firestore = getFirestore();
    return (
      firestore
        .collection("customers")
        .where("deleted", "==", false)
        //.orderBy("name")
        .get()
        .then(function(querySnapshot) {
          let customers = [];
          querySnapshot.forEach(function(doc) {
            customers.push({
              id: doc.id,
              ...doc.data()
            });
          });
          dispatch({ type: "FETCH_CUSTOMERS_SUCCESS", payload: customers });
          resolve(customers);
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
          dispatch({ type: "FETCH_CUSTOMERS_FAILED", data: [] });
        })
    );
  });
};

// export const list = () => {
//   return (dispatch, getState, { getFirebase, getFirestore }) => {
//     const firestore = getFirestore();

//     firestore
//       .collection("customers")
//       .get()
//       .then(function(querySnapshot) {
//         let customers = [];
//         querySnapshot.forEach(function(doc) {
//           customers.push({
//             id: doc.id,
//             ...doc.data()
//           });

//           dispatch({ type: "FETCH_CUSTOMERS_SUCCESS", payload: customers });
//         });
//       })
//       .catch(function(error) {
//         console.log("Error getting documents: ", error);
//         dispatch({ type: "FETCH_CUSTOMERS_FAILED", data: [] });
//       });
//   };
// };

// export const list = () => {
//   return async (dispatch, getState, { getFirebase, getFirestore }) => {
//     return new Promise(async (resolve, reject) => {
//       const firestore = getFirestore();
//       return await firestore
//         .collection("customers")
//         .get()
//         .then(function(querySnapshot) {
//           let customers = [];
//           querySnapshot.forEach(function(doc) {
//             customers.push({
//               id: doc.id,
//               ...doc.data()
//             });

//             dispatch({ type: "FETCH_CUSTOMERS_SUCCESS", payload: customers });
//             console.log("returning", customers);
//             return resolve(customers);
//           });
//         });
//     }).catch(function(error) {
//       console.log("Error getting documents: ", error);
//       dispatch({ type: "FETCH_CUSTOMERS_FAILED", data: [] });
//     });
//   };
// };

export const getById = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //console.log("edit++");
    dispatch({ type: "FETCH_CUSTOMER_BEGIN", payload: null });
    const firestore = getFirestore();

    firestore
      .collection("customers")
      .doc(request)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          dispatch({
            type: "FETCH_CUSTOMER_SUCCESS",
            payload: { id: doc.id, ...doc.data() }
          });
        } else {
          dispatch({ type: "FETCH_CUSTOMER_FAILED", payload: {} });
        }
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
        dispatch({ type: "FETCH_CUSTOMER_FAILED", payload: [] });
      });
  };
};

export const update = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "UPDATE_CUSTOMER_BEGIN" });
    const firestore = getFirestore();
    const auth = getState().firebase.auth;
    let { id, ...customer } = getState().customer.edit.data;
    Object.assign(customer, request);
    customer.updatedOn = new Date();
    customer.updatedBy = auth.uid;

    firestore
      .collection("customers")
      .doc(id)
      .update(customer)
      .then(function(doc) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Kund uppdaterad."
        });
        ownProps.history.push("/customer/list");
      })
      .catch(function(error) {
        console.log("Error updating document: ", error);
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
      });
  };
};


export const deleteCustomer = (id, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "DISABLE_CUSTOMER_BEGIN" });
    const firestore = getFirestore();

    firestore
      .collection("customers")
      .doc(id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          let customer = doc.data();
          customer.deleted = true;
          customer.disabled = true
          firestore
            .collection("customers")
            .doc(doc.id)
            .update(customer)
            .then(function(doc) {
              const disableUser = {id: customer.users[0], disabled : true}
              //Now disable user associated
              dispatch(updateUserStatus(disableUser));

              dispatch({
                type: "NOTIFICATION_SUCCESS",
                payload: "Kund uppdaterad."
              });
              dispatch(list());
              //ownProps.history.push("/customer/list");
            });
        } else {
          //dispatch({ type: "FETCH_CUSTOMER_FAILED", payload: {} });
        }
      })
      .catch(function(error) {
        console.log("Error disable customer: ", error);
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
      });
  };
};

export const disableCustomer = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "DISABLE_CUSTOMER_BEGIN" });
    const firestore = getFirestore();

    firestore
      .collection("customers")
      .doc(request.id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          let customer = doc.data();
          customer.disabled = request.disabled;

          firestore
            .collection("customers")
            .doc(doc.id)
            .update(customer)
            .then(function(doc) {
              const disableUser = {id: customer.users[0], disabled : request.disabled}
              //Now disable user associated
              dispatch(updateUserStatus(disableUser));

              dispatch({
                type: "NOTIFICATION_SUCCESS",
                payload: "Kund uppdaterad."
              });
              dispatch(list());
              //ownProps.history.push("/customer/list");
            });
        } else {
          //dispatch({ type: "FETCH_CUSTOMER_FAILED", payload: {} });
        }
      })
      .catch(function(error) {
        console.log("Error disable customer: ", error);
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
      });
  };
};
