import React, { useState } from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import Ballot from '@material-ui/icons/Ballot'
import LocalLaundryService from '@material-ui/icons/LocalLaundryService'
import Assignment from '@material-ui/icons/Assignment'
import Comment from '@material-ui/icons/Comment'
import Face from '@material-ui/icons/Face'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import { Link } from "react-router-dom"
import { connect } from "react-redux";
import { signOut } from "../../store/actions/authActions";
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Description from '@material-ui/icons/Description'
import LockIcon from '@material-ui/icons/Lock';
import ListIcon from '@material-ui/icons/List';
import BellIcon from '@material-ui/icons/AccessAlarms';
import DialogDefault from "./dialogDefault";


const NavItems = props => {
  const [dialog, setDialog] = useState({ open: false })

  const handleClickCancel = () => {
    setDialog({
      open: false,
      id: null
    });
  }
  
  return (
    <>
      <div>

        <ListItem button component={Link} to="/">     
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Start" />
        </ListItem>

        {props.auth.user.role === "SuperAdmin" ? (
        <ListItem button component={Link} to="/customer/list">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Kunder" />
        </ListItem>) : null}

        {props.auth.user.role === "SuperAdmin" ? (
        <ListItem button component={Link} to="/notifications/list">
          <ListItemIcon>
            <BellIcon />
          </ListItemIcon>
          <ListItemText primary="Notifikationer (test)" />
        </ListItem>) : null}


        {(props.auth.user.role === "CustomerAdmin" || props.auth.user.role === "SuperAdmin" )? (  
        <>
        <ListItem button component={Link} to="/controlRule/list">
          <ListItemIcon>
            <Ballot />
          </ListItemIcon>
          <ListItemText primary="Kontrollpunkter" />
        </ListItem>
        </>
        ):null}

      {props.auth.user.role === "CustomerAdmin"? (
        <>
        <ListItem button component={Link} to="/unit/list">
          <ListItemIcon>
            <LocalLaundryService />
          </ListItemIcon>
          <ListItemText primary="Enheter" />
        </ListItem>
        <ListItem button component={Link} to={"/task/list/" + props.auth.user.customerId}>
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Arbetsuppgifter" />
        </ListItem>
       
        <ListItem button component={Link} to="/report/">
          <ListItemIcon>
            <Comment />
          </ListItemIcon>
          <ListItemText primary="Rapporter" />
        </ListItem>

        <ListItem button component={Link} to="/taskAdmin/list">
          <ListItemIcon>
            <Face />
          </ListItemIcon>
          <ListItemText primary="Användare" />
        </ListItem>
        
        <ListItem button component={Link} to="/notificationSettings/List">
          <ListItemIcon>
            <NotificationsOffIcon></NotificationsOffIcon>
          </ListItemIcon>
          <ListItemText primary="Kalender" />
        </ListItem>
        
      
        </>) : null}

        <ListItem button component={Link} to="/myaccount">
          <ListItemIcon>
          <AccountCircleIcon></AccountCircleIcon>
          </ListItemIcon>
          <ListItemText primary="Mitt konto" />
        </ListItem>

        <ListItem button component={Link} to="/docs/">
          <ListItemIcon>
            <Description></Description>
          </ListItemIcon>
          <ListItemText primary="Dokument" />
        </ListItem>

        


        <ListItem button onClick={() => {
                        setDialog({open:true});
                      }} >
          <ListItemIcon>
          <LockIcon></LockIcon>
          </ListItemIcon>
          <ListItemText primary="Logga ut" />
        </ListItem>

      </div>
    
      <DialogDefault
        module="auth"
        action="signOut"
        open={dialog.open}
        id={dialog.id}
        title={"Logga ut"}
        message={"Vill du logga ut?"}
        handleClickCancel={handleClickCancel}
      />

    </>
  )
}


const mapStateToProps = state => {
  return {
    auth: state.auth,
    firebaseAuth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavItems);