import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

var config = {
    apiKey: "AIzaSyAOhN8r15XnExP11x4BAAC2lBkLy-Xtz7I",
    authDomain: "egnctrl.firebaseapp.com",
    databaseURL: "https://egnctrl.firebaseio.com",
    projectId: "egnctrl",
    storageBucket: "egnctrl.appspot.com",
    messagingSenderId: "921856121512",
    appId: "1:921856121512:web:c1070f52d36a0cc461c09d",
    measurementId: "G-B0MZNYM6X3"
  };
firebase.initializeApp(config);
firebase.firestore().settings({});

export default firebase 