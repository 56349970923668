import { getBadgeCount, setLocalBadgeCount } from "../../helpers/masterDataHelper";

export const listCompletedTasks = (createdOn, type, unitId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
   
    const firestore = getFirestore()
    const auth = getState().auth

    let tasks = [];
    dispatch({ type: "FETCH_REPORTS_COMPLETED_SUCCESS", payload: tasks })

    let query = firestore.collection("completed")
    .where("customerId", "==", auth.user.customerId)
    if (unitId) {
      query = query.where("unitName", "==", unitId);
    }
    query = query.where("completedState","==", "Klar")
    query.orderBy("raportDate", "desc").onSnapshot(function (querySnapshot) {
      tasks = []
      querySnapshot.forEach(function (doc) {
        tasks.push({
            id: doc.id,
            ...doc.data(),
          })
      })
      
      dispatch({ type: "FETCH_REPORTS_COMPLETED_SUCCESS", payload: tasks });
    });
  };
};

export const list = (createdOn, type, unitId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {

    const firestore = getFirestore()
    const auth = getState().auth

    let deviation = [];
    dispatch({ type: "FETCH_REPORTS_SUCCESS", payload: deviation })

    let query = 
    firestore.collection("deviation")
    .where("customerId", "==", auth.user.customerId) 
    if (unitId) {
      query = query.where("unit", "==", unitId);
    }
    query.orderBy("raportDate", "desc").onSnapshot(function (querySnapshot) {
      deviation = []
      querySnapshot.forEach(function (doc) {
          deviation.push({
            id: doc.id,
            typeDeviation: "deviation",
            ...doc.data(),
          })
      })
    });

    console.log("unitId", unitId)

    let query2 = firestore.collection("notcompleted")
    query2 = query2.where("customerId", "==", auth.user.customerId) 
    query2 = query2.where("completedState","==", "Avvikelse")
    if (unitId) {
      query2 = query2.where("unitName", "==", unitId);
    }
    query2.orderBy("raportDate", "desc").onSnapshot(function (querySnapshot2) {
      querySnapshot2.forEach(function (doc2) {
          deviation.push({
            id: doc2.id,
            typeDeviation: "task",
            ...doc2.data(),
          })
      })

      deviation = deviation.sort((a, b) => b.raportDate - a.raportDate)
      dispatch({ type: "FETCH_REPORTS_SUCCESS", payload: deviation });

    });
  };
};

export const getBadgeCountByCustomer = (customerId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise((resolve, reject) => {

      if (!customerId) reject("Customer is null");

      const firestore = getFirestore();
      let query = firestore.collection("bell").doc(customerId).onSnapshot(doc => {
        console.log("Current data: ", doc.data());
        const data = doc.data();
        const badge = {
          reportCount: data ? 1 : 0,
          count: data ? 1 : 0
        };
        dispatch({ type: "FETCH_REPORTS_COUNT_SUCCESS", payload: badge });
        resolve(badge.reportCount);
      });
    });
  };
};

export const getCount = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise((resolve, reject) => {
      const firestore = getFirestore();
      let query = firestore.collection("deviation");
      query.onSnapshot(function (querySnapshot) {
        const badgeCount = getBadgeCount(querySnapshot.size);
        const badge = {
          reportCount: querySnapshot.size,
          count : badgeCount
        };
        dispatch({ type: "FETCH_REPORTS_COUNT_SUCCESS", payload: badge } );
        resolve(querySnapshot.size);
      });
    });
  };
};

export const setCount = (reportCount, count) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise((resolve, reject) => {
        setLocalBadgeCount(reportCount);
        const badge = {
          reportCount: reportCount,
          count : count
        };
        dispatch({ type: "FETCH_REPORTS_COUNT_SUCCESS", payload: badge } );
        resolve();
    });
  };
};

export const createComment = (request) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;

    firestore
      .collection("comments")
      .doc(request.id)
      .set({
        comments: firestore.FieldValue.arrayUnion({
          comment: request.comment,
          createdOn: new Date(),
          createdBy: auth.user.uid,
        }),
      }, { merge: true })
      .then(function (response) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "kommentar skapad!",
        });
      })
      .catch((error) => {
        console.log("errors==> " + error.message);
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Fel när kommentar skulle skapas!",
        });
      });
  };
};

export const getDeviationById = (deviationId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise(async (resolve, reject) => {
     
      const firestore = getFirestore()

      try {
        const deviation = await firestore
          .collection("comments")
          .doc(deviationId)      
          .get()          
          resolve(deviation.data());
      } catch (error) {
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
        reject();
      }
    });
  };
}


export const getComments = (deviationId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise(async (resolve, reject) => {
     
      const firestore = getFirestore()

      try {
        const deviation = await firestore
          .collection("comments")
          .doc(deviationId)      
          .get()          
          resolve(deviation.data());
      } catch (error) {
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
        reject();
      }
    });
  };
}



export const getRaportById = (deviationId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise(async (resolve, reject) => {
     
      const firestore = getFirestore()

      try {
        const deviation = await firestore
          .collection("completed")
          .doc(deviationId)      
          .get()          
          resolve(deviation.data());
      } catch (error) {
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
        reject();
      }
    });
  };
}

export const getRaportDeviationById = (deviationId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise(async (resolve, reject) => {
     
      const firestore = getFirestore()

      try {
        const deviation = await firestore
          .collection("deviation")
          .doc(deviationId)      
          .get()          
          resolve(deviation.data());
      } catch (error) {
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
        reject();
      }
    });
  };
}


export const getRaportNotCompleted = (deviationId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    return new Promise(async (resolve, reject) => {
     
      const firestore = getFirestore()

      try {
        const deviation = await firestore
          .collection("notcompleted")
          .doc(deviationId)      
          .get()          
          resolve(deviation.data());
      } catch (error) {
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
        reject();
      }
    });
  };
}


