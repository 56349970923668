import React, { Component } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  list as listReports,
  listCompletedTasks as listCompleted,
  createComment,
  getDeviationById,
  getRaportById,
  getRaportDeviationById,
  getRaportNotCompleted,
  getComments,
  setCount as setReportCount
} from "../../store/actions/reportsActions";
import { list as listUnits } from "../../store/actions/unitActions";
import Moment from "react-moment";
import "moment/locale/sv";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import PrintIcon from "@material-ui/icons/Print";
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { error } from "../../store/actions/toasterActions";
import ReactToPrint from "react-to-print";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import moment from "moment"
import MomentUtils from "@date-io/moment"
import svLocale from "date-fns/locale/sv"

import { getTaskById } from "../../store/actions/taskActions";
import ClockIcon from "@material-ui/icons/AccessTime";
import DateRange from "@material-ui/icons/DateRange";
import Face from "@material-ui/icons/Face";
import { reduxFirestore, getFirestore } from "redux-firestore";


const localeMap = {
  sv: svLocale,
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

class ReportContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      locale: 'sv',
      name: {
        displayName: "Namn",
        value: null,
        required: true,
      },
      description: {
        displayName: "Beskrivning",
        value: null,
        required: true,
      },
      startDate: {
        displayName: "Start datum",
        value: null,
        required: true,
      },
      endDate: {
        displayName: "Stopp datum",
        value: null,
        required: true,
      },
      taskAdminId: {
        displayName: "Tilldelad användare",
        value: null,
        required: true,
      },
      unitId: {
        displayName: "Enhet",
        value: null,
        required: true,
      },
      controlRuleId: {
        displayName: "Kontrollpunkt",
        value: null,
        required: true,
      },
      controlRuleMin: {
        displayName: "Min värde",
        value: null,
        required: false,
      },
      controlRuleMax: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinCold: {
        displayName: "Min värde",
        value: null,
        required: false,
      },
      controlRuleMaxCold: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinHot: {
        displayName: "Min värde",
        value: null,
        required: false,
      },
      controlRuleMaxHot: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinFrozen: {
        displayName: "Max värde",
        value: null,
        required: false,
      },
      controlRuleMaxFrozen: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdFishFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdFishFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdFridge: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMinColdHotFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleMaxColdHotFood: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleFrozenIn: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleTwoHours: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      controlRuleFourHours: {
        displayName: "Max värde",
        value: null,
        required: false,
      },

      daily: {
        displayName: "Daily",
        value: false,
        required: false,
      },
      weekly: {
        displayName: "Weekly",
        value: false,
        required: false,
      },
      monthly: {
        displayName: "Monthly",
        value: false,
        required: false,
      },
      quarter: {
        displayName: "Quarter",
        value: false,
        required: false,
      },
      eachtwomonths: {
        displayName: "EachTwoMonths",
        value: false,
        required: false,
      },
      monday:{
        displayName: "Monday",
        value: false,
        required: false,
      },
      tuesday:{
        displayName: "Tuesday",
        value: false,
        required: false,
      },
      wednesday:{
        displayName: "Wednesday",
        value: false,
        required: false,
      },
      thursday:{
        displayName: "Thursday",
        value: false,
        required: false,
      },
      friday:{
        displayName: "Friday",
        value: false,
        required: false,
      },
      saturday:{
        displayName: "Saturday",
        value: false,
        required: false,
      },
      sunday:{
        displayName: "Sunday",
        value: false,
        required: false,
      },
      repeatId:{
        displayName: "Notifikation frekvens",
        value: 0,
        required: true,
      },
      customRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      },
      monthRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      }      ,
      yearRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      } ,
      weakRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      },
      dayRepeat: {
        displayName: "Sunday",
        value: 0,
        required: false,
      },
      endRepetition:{
        displayName: "End repetition",
        value: 0,
        required: false
      }
      
    }



  }
  // const ReportContainer = (props) => {


  // useEffect(() => {


  // }, [])


  handleSubmit = async (event) => {
    event.preventDefault();

    const message = {
      to: this.state.name.value,
      sound: 'default',
      title: 'Du har tilldelats en uppgift',
      body: 'Klicka här för att komma till EGNCTRL',
      data: { trigger: { seconds: 20 } },
      _displayInForeground: true,
      badge: 1,
      trigger: { seconds: 20 }
    }

    fetch('https://exp.host/--/api/v2/push/send', {
    'mode': 'no-cors',
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Accept-encoding': 'gzip, deflate',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    }).then(function(res) {  
      console.log("response", res)
    })


 
  };


  handleChange = (e) => {

    if (!e.target) return true;

    let currentField = this.state[e.target.name];
    if (currentField) {
      if (e.target.type === "checkbox") {
        currentField.value = e.target.checked;
      } else {
        currentField.value = e.target.value;
      }

      this.setState({
        [e.target.name]: currentField,
      })

    }
  };

  render() {
    return (
      <>
        <Grid item xs={12} md={12} lg={12}>

          <Grid container spacing={1}>
            <Grid item xs={12} lg={9}>
            <h1>Test</h1>
            </Grid>
            <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px'}}>     
                  
            </Grid>
          </Grid>
        
          <Paper style={classes.paper}>
          
          <form className={classes.root} noValidate autoComplete="off"  onSubmit={this.handleSubmit}>


          <TextField id="standard-basic" label="Standard"  id="name"
                                  multiline={true}
                                  name="name"
                                  label="Token"
                                  onChange={this.handleChange}/>

            <TextField id="standard-basic" label="Standard"  id="dayRepeat"
                                  multiline={true}
                                  name="dayRepeat"
                                  label="Dag 1 - 7"
                                  onChange={this.handleChange}/>

            <TextField id="standard-basic" label="Standard"  id="weakRepeat"
                                  multiline={true}
                                  name="weakRepeat"
                                  label="Varje X vecka(or)..."
                                  onChange={this.handleChange}/>
         


            <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  Skapa notifikation
                </Button>

          </form>

          </Paper>
          <br></br>
        
        </Grid>

      

        
      </>
    );
  };
}

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
  headline: { 
    color: "grey"
  }
};

const mapStateToProps = (state) => {
 
};

const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
