import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { listTaskAdmin, disableTaskAdmin } from "../../store/actions/taskAdminActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import DialogDefault from "../Shared/dialogDefault";
import TablePagination from "@material-ui/core/TablePagination";


const ListTaskAdminContainer = (props) => {
  const [dialog, setDialog] = useState({open : false, uid:null});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    props.listTaskAdmin();
  }, []);

  const handleClickCancel = () => {
    setDialog({
      open: false,
      id: null
    });
  }

  const taskAdmins = props.taskAdmins ? props.taskAdmins.data : [];

  return (
    <>
    <Grid item xs={12} md={12} lg={12}>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
        <h1>Användare i din organisation</h1>
        </Grid>
        <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px 0'}}>
        <Button variant="contained" component={Link} to="/taskAdmin/create">
          <i className="gg-add"></i> Skapa ny användare
        </Button>
        </Grid>
      </Grid>

      <Paper style={classes.paper}>
        
        <p>
        Skapa användare och delegera arbetsuppgifter. <br></br>
På den här sidan registrerar du de som ska utföra arbetsuppgifter. 
<br></br>I vårt grundabonnemang kan du registrera tre personer inklusive dig själv. Behöver du fler användare kan du enkelt uppgradera ditt abonnemang genom att kontakta oss på EGNCTRL.

        </p>
       
        <TableContainer style={{ padding: 1 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Id</TableCell> */}
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Namn
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Email
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskAdmins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.name}>
                  {/* <TableCell>{row.id}</TableCell> */}
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  {/* <TableCell>
                    <Fab
                        size="small"
                        color="black"
                        aria-label="add"
                        component={Link}
                        to={"/taskAdmin/edit/" + row.id}
                      >
                        <EditIcon />
                      </Fab> 
                  </TableCell>*/}
                  <TableCell>
                    <Button
                      variant="contained" size="small"       
                      onClick={() => {
                        props.disableTaskAdmin({
                          id: row.id,
                          disabled: !row.disabled,
                        });
                      }}
                    >
                      {row.disabled ? "Aktivera" : "Avaktivera"}
                    </Button>
                    </TableCell>
                   
                    <TableCell>
                    <Button
                      variant="contained" size="small"      
                      onClick={() => {
                        setDialog({ open: true, uid: row.id });
                      }}
                    >
                      {"Radera"}
                    </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Rader per sida"
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={taskAdmins.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>

    <DialogDefault
        module="taskAdmin"
        action="deleteTaskAdmin"
        open={dialog.open}
        uid={dialog.uid}
        title={"Uppgiftsadministratör"}
        message={"Radera användare?"}
        handleClickCancel={handleClickCancel}
      />
      </>
  );
};
const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
};

const mapStateToProps = (state) => {
  return {
    taskAdmins: state.taskAdmin.list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    listTaskAdmin: () => dispatch(listTaskAdmin()),
    disableTaskAdmin: request => dispatch(disableTaskAdmin(request, ownProps)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListTaskAdminContainer);
