const initialState = {
  notification: {}
};

const toasterReducer = (state = initialState, action) => {
  //console.log("toasterReducer==>" + JSON.stringify(action));
  switch (action.type) {
    case "NOTIFICATION_SUCCESS":
      return {
        notification: {
          type: "success",
          message: action.payload
        }
      };
    case "NOTIFICATION_FAILED":
      return {
        notification: {
          type: "error",
          message: action.payload
        }
      };
      case "NOTIFICATION_CLEAR":
      return {
        notification: {}
      };

    default:
      return state;
  }
};

export default toasterReducer;
