import React, { Component } from "react";
import { connect } from "react-redux";
import { getTaskById } from "../../store/actions/taskActions";
import EditTask from "./EditTask";
import _ from "lodash/core";

class EditTaskContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    await this.props.getTaskById(id);
  };
  
  render() {
    const editTask = this.props.edit;
    if (_.isEmpty(editTask.data)) {
      return <></>;
    }
    console.log("editTask.data", editTask.data);
    return <EditTask task={editTask.data} />;
  }
}

const mapStateToProps = state => {
  return {
    edit: state.task.edit
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTaskById: request => dispatch(getTaskById(request))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskContainer);
