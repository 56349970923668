import { list } from "../../store/actions/customerActions";

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "SIGN_IN_BEGIN" });
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        // dispatch({ type: "SIGN_IN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Användarnamn eller lösenord stämmer inte överens",
        });
        dispatch({ type: "SIGN_IN_FAILED", payload: "OH NO" });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    debugger;
    firebase
      .auth()
      .signOut()
      .then(() => {
        //dispatch({ type: "SIGN_OUT_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "SIGN_OUT_FAILED", err });
      });
  };
};

export const updateUserStatus = (request) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    var updateUserStatus = firebase
      .functions()
      .httpsCallable("updateUserStatus");

    updateUserStatus(request)
      .then(function () {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Användare uppdaterad!",
        });

        dispatch(list());
      })
      .catch((error) => {
        console.log("errors==> " + error.message);
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Användare uppdaterad fel!",
        });
      });
  };
};

export const updateUser = (request, ownProps) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "SIGN_IN_BEGIN" });
    const firebase = getFirebase();
    var updateUser = firebase.functions().httpsCallable("updateUser");

    updateUser(request)
      .then(function () {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Användare uppdaterad!",
        });
        //ownProps.history.push("/");
        //dispatch(signOut());
      })
      .catch((error) => {
        debugger;
        console.log("errors==> " + error.message);
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Användare uppdaterad ej!" + error.message,
        });
      })
      .finally(() => {
        dispatch({ type: "UPDATE_USER_END" });
      });
  };
};

export const deleteUser = (request, ownProps) => {
  return (dispatch, getState, { getFirebase }) => {
    return new Promise((resolve, reject) => {
      const firebase = getFirebase();
      var deleteUser = firebase.functions().httpsCallable("deleteUser");
      deleteUser(request)
        .then(function () {
          resolve();
          // dispatch({
          //   type: "NOTIFICATION_SUCCESS",
          //   payload: "Användaren har tagits bort!",
          // });
        })
        .catch((exception) => {
          console.log("exception==> ", exception);
          reject(exception);
          // dispatch({
          //   type: "NOTIFICATION_FAILED",
          //   payload: "Användarens radering misslyckades!" + error.message,
          // });
        });
    });
  };
};

export const verifyAuth = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        //console.log("onAuthStateChanged==> signedIn");

        const payload = {
          isLoaded: true,
          uid: user.uid,
          authenticated: true,
          displayName: null,
          email: user.email,
          role: null,
          customerId: null,
        };

        firestore
          .collection("users")
          .doc(user.uid)
          .get()
          .then((userData) => {
            if (userData.exists) {
              payload.displayName = userData.data().displayName;
              payload.role = userData.data().role;

              if (payload.role === "CustomerAdmin") {
                //Get customer Id
                firestore
                  .collection("customers")
                  .where("users", "array-contains", user.uid)
                  .get()
                  .then((snapshot) => {
                    snapshot.forEach((doc) => {
                      
                      payload.customerId = doc.id;
                      payload.customerName = doc.data().name;
                      payload.customerType = doc.data().customerType;
                      payload.licenseType = doc.data().licenseType;
                      payload.subscriptionValidUntil = doc.data().subscriptionValidUntil
                      console.log("DOC PAYLOAD", payload)

                      if (typeof(Storage) !== "undefined") {
                        sessionStorage.setItem("licensType",  payload.licenseType)
                        sessionStorage.setItem("subscriptionValidUntil",  payload.subscriptionValidUntil);
                      } 

                      return;
                    });
                    dispatch({ type: "SIGN_IN_SUCCESS", payload: payload });
                  });
              } else if (payload.role === "TaskAdmin") {
                  dispatch({
                    type: "NOTIFICATION_FAILED",
                    payload: "Du har inte tillgång till backoffice",
                  });
                  dispatch({ type: "SIGN_IN_FAILED", payload: "OH NO" });

                  dispatch(signOut());

                // firestore
                //   .collection("taskAdmins")
                //   .doc(user.uid)
                //   .get()
                //   .then((userData) => {
                //     if (userData.exists) {
                //       payload.customerId = userData.data().customerId;
                //       dispatch({ type: "SIGN_IN_SUCCESS", payload: payload });
                //     }
                //   });
              } else {
                dispatch({ type: "SIGN_IN_SUCCESS", payload: payload });
              }
            }
          })
          .catch((err) => {
            console.log("No user found", err);
          });
      } else {
        //console.log("onAuthStateChanged==> signedOut");
        dispatch({ type: "SIGN_OUT_SUCCESS" });
      }
    });
  };
};

export const sendForgotPassword = (email, ownProps) => {
  return async (dispatch, getState, { getFirebase }) => {
    return new Promise(async (resolve, reject) => {
      const firebase = getFirebase();

      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          dispatch({
            type: "NOTIFICATION_SUCCESS",
            payload: "Återställnings lösenord har skickats till dig.",
          });
          ownProps.history.push("/signin");
          return resolve({});
        })
        .catch((err) => {
          console.log("err", err);
          dispatch({
            type: "NOTIFICATION_FAILED",
            payload: err.message,
          });
          return reject({});
        });
    });
  };
};
