const initialState = {
  list: {
    isLoading: false,
    data: []
  },
  edit: {
    isLoading: false,
    data: {}
  }
};

const notificationSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NOTIFICATION-SETTINGS_SUCCESS":
      return {
        ...state,
        list: {
          data: action.payload,
          isLoading: false
        }
      };
    case "CREATE_NOTIFICATION-SETTINGS_SUCCESS":
      return {
        ...state,
        list: {
          data: [],
          isLoading: false
        }
      };
    case "FETCH_NOTIFICATION-SETTING_SUCCESS":
      return {
        ...state,
        edit: {
          isLoading: false,
          data: action.payload
        }
      };
    case "UPDATE_NOTIFICATION-SETTINGS_SUCCESS":
      return {
        ...state,
        list: {
          data: [],
          isLoading: false
        }
      };
    default:
      return state;
  }
};

export default notificationSettingsReducer;
