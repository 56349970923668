export const success = request => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "NOTIFICATION_SUCCESS", payload: request });
  };
};

export const error = request => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({ type: "NOTIFICATION_FAILED", payload: request });
  };
};
