export const create = (request, ownProps) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;

    firestore
      .collection("units")
      .add({
        ...request,
        createdOn: new Date(),
        createdBy: auth.user.uid,
        customerId: auth.user.customerId
      })
      .then(function(response) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Enhet skapad!"
        });

        dispatch({
          type: "CREATE_UNIT_SUCCESS"
        });

        ownProps.history.push("/unit/list");
      })
      .catch(error => {
        console.log("errors==> " + error.message);
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Create Unit failed!"
        });
      });
  };
};

export const getById = (id, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log("id", id);
    const firestore = getFirestore();
    firestore
      .collection("units")
      .doc(id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          dispatch({
            type: "FETCH_UNIT_SUCCESS",
            payload: { id: doc.id, ...doc.data() }
          });
        } else {
          dispatch({ type: "FETCH_UNIT_FAILED", payload: {} });
        }
      })
      .catch(function(error) {
        console.log("Error getting unit: ", error);
        dispatch({ type: "FETCH_UNIT_FAILED", payload: [] });
      });
  };
};

export const update = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().firebase.auth;
    let { id, ...unit } = getState().unit.edit.data;
    Object.assign(unit, request);
    unit.updatedOn = new Date();
    unit.updatedBy = auth.uid;

    firestore
      .collection("units")
      .doc(id)
      .update(unit)
      .then(function(doc) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Enhet uppdaterad!"
        });

        dispatch({ type: "UPDATE_UNIT_SUCCESS" });

        ownProps.history.push("/unit/list");
      })
      .catch(function(error) {
        console.log("Error Unit update: ", error);
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
      });
  };
};

export const remove = (id, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("units")
      .doc(id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          firestore
            .collection("units")
            .doc(id)
            .delete()
            .then(function(doc) {
              dispatch({
                type: "NOTIFICATION_SUCCESS",
                payload: "Enhet raderad!"
              });

              dispatch({ type: "DELETE_UNIT_SUCCESS" });

              dispatch(list());
            })
            .catch(function(error) {
              console.log("Error Unit delete: ", error);
              dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
            });
        }
      });
  };
};

export const list = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;
    firestore
      .collection("units")
      .orderBy("name")
      .where("customerId", "==", auth.user.customerId)
      .get()
      .then(function(querySnapshot) {
        let units = [];
        querySnapshot.forEach(function(doc) {
          let unit = doc.data()
          
          // if(unit.customerId && unit.customerId == auth.user.customerId){
            units.push({
              id: doc.id,
              ...doc.data()
            });
          
         
        });
        dispatch({ type: "FETCH_UNITS_SUCCESS", payload: units });
      })
      .catch(function(error) {
        console.log("Error getting units : ", error);
        dispatch({ type: "FETCH_UNIT_FAILED", data: [] });
      });
  };
};
