import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  list as listReports,
  listCompletedTasks as listCompleted,
  createComment,
  getDeviationById,
  getRaportById,
  getRaportDeviationById,
  getRaportNotCompleted,
  getComments,
  setCount as setReportCount
} from "../../store/actions/reportsActions";
import { list as listUnits } from "../../store/actions/unitActions";
import Moment from "react-moment";
import "moment/locale/sv";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import PrintIcon from "@material-ui/icons/Print";
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { error } from "../../store/actions/toasterActions";
import ReactToPrint from "react-to-print";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import moment from "moment"
import MomentUtils from "@date-io/moment"
import svLocale from "date-fns/locale/sv"

import { getTaskById } from "../../store/actions/taskActions";
import ClockIcon from "@material-ui/icons/AccessTime";
import DateRange from "@material-ui/icons/DateRange";
import Face from "@material-ui/icons/Face";
import { reduxFirestore, getFirestore } from "redux-firestore";


const localeMap = {
  sv: svLocale,
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const ReportContainer = (props) => {

  let report
  let reportCompleted

  const [unitId, setUnitId] = React.useState("");
  const [unitIdCompleted, setUnitIdCompleted] = React.useState("");
  const [typeId, setTypeId] = React.useState("");
  const [createdOn, setCreatedOn] = React.useState(new Date());
  const [raportDate, setRaportDate] = React.useState(new Date());
  const [taskComments, setTaskComments] = React.useState({
    open: false,
    id: null,
    description: null,
    comment: null,
    comments: [],
  })

  useEffect(() => {

    props.listCompleted(createdOn, null, null)
    props.listReports(createdOn, null, null)
    props.listUnits()
    props.setReportCount(props.report.badge.reportCount, 0)
    
  }, [])

  // console.log("props.report.list.data",props.report.list.data)
  // console.log("props.reportCompleted.completedList.data", props.report.completedList.data)

  report = props.report ? props.report.list.data : []
  reportCompleted = props.report ? props.report.completedList.data : []

  const units = props.unit ? props.unit.list.data : []

  const handleChangeUnitCompleted = (event) => {
    console.log("event.target", event.target)
    props.listCompleted(createdOn, typeId, event.target.value)
    report = props.report ? props.report.list.data : []
    setUnitIdCompleted(event.target.value)
  }

  const handleChangeUnit = (event) => {
    props.listReports(createdOn, typeId, event.target.value)
    report = props.report ? props.report.list.data : []
    setUnitId(event.target.value)
  }

  const handleChangeType = (event) => {
    props.listReports(createdOn, event.target.value, unitId)
    report = props.report ? props.report.list.data : []
    setTypeId(event.target.value)
  };

  const handleCreatedDateChange = (event) => {
    setCreatedOn(event)
    props.listReports(event, typeId, unitId);
    report = props.report ? props.report.list.data : []
  }

  const handleClickOpen = async (report) => {

    console.log("report", report)

    const deviation = await props.getDeviationById(report.id);
    console.log("selectedDeviation", report)

    let comments=[]
    if(deviation){
      comments= deviation.comments.sort((a, b) => b.createdOn - a.createdOn)
    }
    
    setTaskComments({
      ...report,
      comments: deviation ? comments : [],
    })
    
    
  }

  const handleClose = () => {
    setTaskComments({ ...taskComments, open: false })
  }

  const handleChange = (e) => {
    setTaskComments({ ...taskComments, comment: e.target.value })
  }

  const saveComment = async () => {

    console.log("taskComments", taskComments)

    if (!taskComments.comment) {
      props.error(`Kommentar är obligatorisk`);
      return;
    }
    await props.createComment({
      id: taskComments.id,
      comment: taskComments.comment,
    });

    const deviation = await props.getDeviationById(taskComments.id)
    let comments = deviation.comments

    console.log("comments", comments)

    comments = comments.sort((a, b) => b.createdOn - a.createdOn)

    setTaskComments({
      ...taskComments,
      open: false,
      comment: null,
      comments: deviation ? comments : [],
    })
  }

  //Print report
  const componentRef = useRef();
  const [printReport, setPrintReport] = React.useState({
    open: false,
    id: null,
    deviation: {},
  })

  const handlePrintClickOpen = async (report) => {

    let data 
    if(report.type === "completed"){

      let comments = await props.getComments(report.id)
      data = await props.getRaportById(report.id) 
      setPrintReport({
        ...report,
        deviation: data,
        comments: comments
      })
    }

    if(report.type === "deviation"){
     
      let comments = await props.getComments(report.id)
      data = await props.getRaportDeviationById(report.id)
      if(!data){
        data = await props.getRaportNotCompleted(report.id)
      }

      setPrintReport({
        ...report,
        deviation: data,
        comments: comments
      })
    }
    
  }

  const handlePrintClose = () => {
    setPrintReport({ ...printReport, open: false })
  }
  
  const getUnitNameById =  (unitId) => {
    let name = "e"
    const firestore = getFirestore()
    firestore.collection("units")
    .doc(unitId)
    .get()
    .then((doc) => { 
      name = doc.get("name")
      console.log(doc.get("name"))     
      
    })
    .catch((error) => { })
  }

  const getControlResultFields = (controlRuleId, thisTask) => {

  if(controlRuleId === "wFvh2AAmY3B2IvZY25AU" 
  || controlRuleId === "fCNqBtAPGD3eJWOmELxP" 
  || controlRuleId === "HWrn1AD66BmsteRtKIO3"
  || controlRuleId === "6LwHq1LqNmxm3u5kdyPF"
  || controlRuleId === "DjqOZcYxxHWBl5F3O5cS"
  || controlRuleId === "F0zFhYA1HrlIeVXz6wBs"
  || controlRuleId === "EW3Zr5ZwdypZ8xEILBHF"
  || controlRuleId === "FtQZdnpPR8tWPKCp2rqz"){
   return ( 
    <>
      <br></br>
      <small><b>Temperaturkontroll:</b></small> {thisTask.selectedColdTemp}
      <br></br>
      <small><b>Maträtt:</b></small> {thisTask.controlGrossery} 
      {/* {thisTask.elseComments && <><p><b>Övrigt:</b><br></br>{thisTask.elseComments}</p></> }   */}
    </>
   )}
 
   if(controlRuleId === "vpsmWbFCtmKIAvDoIJZu" || controlRuleId === "7n8E1hN17c5Cz15DELCd" || controlRuleId ==="O9BQfuhYxbPNqBkUgznX" || controlRuleId === "KoeiG9iYHfjQnZHDl0st" || controlRuleId === "PK5LoXdp7jOq9IykSU3b"){
   return ( 
    <>
        <br></br>
        <small><b>Temperaturkontroll:</b></small> {thisTask.selectedColdTemp} 
        <br></br>  
        <small><b>Vara:</b></small> {thisTask.controlGrossery} <br></br>
        <small>{thisTask.emballageOk === true && 'Emballage var oskadd'}{thisTask.emballageNotOk === true && 'Emballage var skadat'}</small>   
    </>
   )}

   if(controlRuleId === "DMGRi5qiG80qHU3VQSeh"){
   return ( 
    <>
      <br></br>
      <small>Isvatten:</small> {thisTask.selectedColdTemp}
      <br></br>
      <small>Kokande vatten:</small> {thisTask.selectedHotTempFood}     
    </>
   )}

   if(controlRuleId === "irdQkcnWcj1TaN3QUwnT"){
   return ( 
    <>
      <br></br>
      <small>Livsmedel:</small> {thisTask.controlGrossery}
      <br></br>
      <small>Temperaturkontroll in:</small> {thisTask.selectedColdTempFood} 
      <br></br>
      <small>Temperaturkontroll 2 timmar:</small> {thisTask.selectedTwoHour}
      <br></br>
      <small>Temperaturkontroll 4 timmar:</small> {thisTask.selectedFourHour}
    </>
   )}

   if(controlRuleId === "hTGooVyTc6otIS6YETGR"){
   return ( 
    <>
      <br></br>
      <small>Område/maskin:</small> {thisTask.controlGrossery}        
    </>
   )}

   if(controlRuleId === "N1YUJSnz0EtGtnEk9G9B" || controlRuleId === "IvEWxPqTYjgw7cQr9WYW" || controlRuleId === "f5bLTErvB1C3kQnY1Tuk"){
    return ( 
     <>     
     </>
    )}   
} 

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={9}>
          <h1>Rapporter</h1>
          </Grid>
          <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px'}}>     
                
          </Grid>
        </Grid>
      
        <Paper style={classes.paper}>
         
          <p>
          Här finns samtliga rapporter samlade. <br></br>
          Färdiga uppgifter är dom arbetsuppgifter som är utförda utan avvikelse.
I kolumnen Avvikelser och fristående rapporter hittar du rapporterade avvikelser samt fristående rapporter om avvikelse.
<br></br>
Senast inkomna rapport hamnar alltid överst i listan.
<br></br>
Du kan filtrera rapporterna på enhet om du vill se en lista på avvikelser på en specifik enhet.
<br></br>
Du kan på varje rapport skriva kommentarer samt skriva ut rapporten.
          </p>

        </Paper>
        <br></br>
        <Grid container spacing={1}>
            <Grid item xs={12} lg={6}>
              <Paper style={classes.paper}>

                <Grid container>
                  <Grid item lg={6}>
                    <h2><SpeakerNotesIcon fontSize="medium" style={{color:"green"}}/> Färdiga uppgifter</h2>
                    <p>Färdiga uppgifter utan avvikelse.</p>
                    <small>Senast inkommen längst upp.</small>
                  </Grid>
                  <Grid item lg={6}  style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px'}}>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      onChange={handleChangeUnitCompleted}
                      defaultValue="2000"
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="2000">
                        <em>Filtrera på enhet</em>
                      </MenuItem>
                      <MenuItem value="">
                        <em>Alla</em>
                      </MenuItem>
                      {units.map((row) => (
                        <MenuItem value={row.name}>{row.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              
                <TableContainer>

                  <Table>

                    <TableBody>
                      {reportCompleted.map((row) => (

                          <TableRow key={row.id}>

                            <TableCell align="left">
                              <Grid container>
                                <Grid item xs={10} style={{ textAlign: "left" }}>
                                <h3>{row.name}</h3>                             
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: "right" }}>
                            
                                  <IconButton
                                    aria-label="comments"
                                    className={classes.margin}
                                    onClick={handleClickOpen.bind(this, {
                                      open: true,
                                      id: row.id,
                                      description: row.name,
                                    })}
                                  >
                                    <CommentIcon fontSize="small" />
                                  </IconButton>

                                  <IconButton
                                    aria-label="print"
                                    className={classes.margin}
                                    onClick={handlePrintClickOpen.bind(this, {
                                      open: true,
                                      id: row.id,
                                      type: 'completed'
                                    })}
                                  >
                                    <PrintIcon fontSize="small" />
                                  </IconButton>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4} style={{ textAlign: "left" }}>                                                            
                                <small>
                                  <b style={classes.headline}>Inkom:</b>
                                </small>
                                <br></br>
                                <DateRange style={{fontSize: 13}} />  <Moment locale="sv" fromNow format={"MMMM Do YYYY"}>
                                  {row.raportDate && row.raportDate.toDate()}
                                </Moment>         
                                <br></br>
                                <ClockIcon style={{fontSize: 13}} /> <Moment locale="sv" fromNow format={"HH:mm"}>
                                  {row.raportDate && row.raportDate.toDate()}
                                </Moment>                                     
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: "left" }}>                                
                                <small>
                                  <b style={classes.headline}>Rapporterat utav:</b>
                                </small>
                                <br></br>
                                <Face style={{fontSize: 13}} /> {row.raporter ? row.raporter.replace('"','').replace('"','') : "-"}                               
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: "left" }}>                                
                                <small>
                                  <b style={classes.headline}>Gäller enhet:</b>
                                </small>
                                <br></br>
                                {row.unitName ? row.unitName : "-"}                                
                              </Grid>
                              <Grid item xs={8} style={{ textAlign: "left", marginTop:10 }}>                               
                                <small>
                                  <b style={classes.headline}>Rapport:</b>
                                </small>                               
                                
                                {row.elseComments &&     <>                                                      
                                
                                  <br></br>
                                  {row.elseComments ? row.elseComments : "-"}</>
                             
                                }
                                {getControlResultFields(row.controlRuleId, row)}
                              </Grid>
                              <Grid item xs={4} style={{ textAlign: "right", marginTop:10 }}>
                                <a href={row.image} target="_blank">
                                  <img src={row.image} width="50" style={{borderRadius: "10px"}}></img>
                                </a>
                              </Grid>

                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6}>
              
              <Paper style={classes.paper}>

                <Grid container>
                  <Grid item lg={6}>
                  <h2><SpeakerNotesOffIcon fontSize="medium"  style={{color:"red"}}/> Avvikelser & fristående rapporter</h2>                
                  <p>Uppgifter med avvikelse och fristående rapporter.</p>
                  <small>Senast inkommen längst upp.</small>
                  </Grid>
                  <Grid item lg={6}  style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px'}}>
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      onChange={handleChangeUnit}
                      defaultValue="2000"
                      input={<BootstrapInput />}
                    >
                      <MenuItem value="2000">
                        <em>Filtrera på enhet</em>
                      </MenuItem>
                      <MenuItem value="">
                        <em>Alla</em>
                      </MenuItem>
                      {units.map((row) => (
                        <MenuItem value={row.name}>{row.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
               
                <TableContainer>                  
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      {report.map((row) => (

                        <TableRow key={row.id}>
                          <TableCell align="left">
                            <Grid container>                        
                              <Grid item xs={10} style={{ textAlign: "left" }}>                            
                                {row.name && <h3>{row.name}</h3>}  
                                {row.taskName && <h3>{row.taskName}</h3>}         
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: "right" }}>
                              
                              <IconButton
                                aria-label="comments"
                                className={classes.margin}
                                onClick={handleClickOpen.bind(this, {
                                  open: true,
                                  id: row.id,
                                  description: row.name,
                                })}
                              >
                                <CommentIcon fontSize="small" />
                              </IconButton>

                              <IconButton
                                aria-label="print"
                                className={classes.margin}
                                onClick={handlePrintClickOpen.bind(this, {
                                  open: true,
                                  id: row.id,
                                  type: 'deviation'
                                })}
                              >
                                <PrintIcon fontSize="small" />
                              </IconButton>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item xs={4} style={{ textAlign: "left" }}>
                                <small>
                                  <b style={classes.headline}>Inkom:</b>
                                </small>
                                <br></br>
                                <DateRange style={{fontSize: 13}} />  <Moment locale="sv" fromNow format={"MMMM Do YYYY"}>
                                  {row.raportDate && row.raportDate.toDate()}
                                </Moment>         
                                <br></br>
                                <ClockIcon style={{fontSize: 13}} /> <Moment locale="sv" fromNow format={"HH:mm"}>
                                  {row.raportDate && row.raportDate.toDate()}
                                </Moment>        
                              </Grid>

                              {row.completedState && 
                                <Grid item xs={4} style={{ textAlign: "left" }}>                                
                                  <small>
                                    <b style={classes.headline}>Användare:</b>
                                  </small>
                                  <br></br>
                                  {row.raporter ? row.raporter.replace('"','').replace('"','') : "-"}                            
                                </Grid>
                              }

                              {!row.completedState && 
                                <Grid item xs={4} style={{ textAlign: "left" }}>                                
                                  <small>
                                    <b style={classes.headline}>Användare:</b>
                                  </small>
                                  <br></br>
                                  <Face style={{fontSize: 13}} /> {row.userName ? row.userName.replace('"','').replace('"','') : "-"}                            
                                </Grid>
                              }
                              
                              {row.completedState && 
                                <Grid item xs={4} style={{ textAlign: "left" }}>                                
                                  <small>
                                    <b style={classes.headline}>Gäller enhet:</b>
                                  </small>
                                  <br></br>
                                  {row.unitName ? row.unitName : "-"}                            
                                </Grid>
                              }

                              {!row.completedState && 
                                <Grid item xs={4} style={{ textAlign: "left" }}>                                
                                  <small>
                                    <b style={classes.headline}>Gäller enhet:</b>
                                  </small>
                                  <br></br>
                                  {row.unit ? row.unit : ""}           
                                </Grid>
                              }
                              
                              {row.deviationRaport && 
                               <Grid item xs={8} style={{ textAlign: "left", marginTop:10 }}>                               
                                <small>
                                  <b style={classes.headline}>Rapport:</b>
                                </small>
                                <br></br>
                                {row.deviationRaport ? row.deviationRaport : "-"}
                               </Grid>
                              }

                              {row.typeDeviation == "task" && 
                               <Grid item xs={8} style={{ textAlign: "left", marginTop:10 }}>                               
                                <small>
                                  <b style={classes.headline}>Rapport:</b>
                                </small>
                                <br></br>
                                {row.elseComments ? row.elseComments : "-"}
                               </Grid>
                              }

                              {row.typeDeviation =="deviation" &&
                               <Grid item xs={8} style={{ textAlign: "left", marginTop:10 }}>                               
                                <small>
                                  <b style={classes.headline}>Rapport:</b>
                                </small>
                                <br></br>
                                {row.description ? row.description : "-"}
                                
                               </Grid>
                              }            

                              <Grid item xs={8} style={{ textAlign: "left" }}>         
                                {getControlResultFields(row.controlRuleId, row)}  
                              </Grid>
                             
                            
                              <Grid item xs={4} style={{ textAlign: "left", marginTop:10 }}>
                                <a href={row.image} target="_blank">
                                  <img src={row.image} width="50" style={{borderRadius: "10px"}}></img>
                                </a>
                              </Grid>


                             
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
       
      </Grid>

      <div>
        <Dialog
          open={taskComments.open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth="true"
        >
          <DialogTitle id="form-dialog-title">
            <b>Noteringar för: {taskComments.description}</b>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="commentId"
              label="Notering"
              type="text"
              fullWidth
              onChange={handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Stäng</Button>
            <Button onClick={saveComment}>Spara</Button>
          </DialogActions>

          <TableContainer style={{ padding: 1 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>Id</TableCell> */}
                  <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                    Kommentar
                  </TableCell>                
                </TableRow>
              </TableHead>
              <TableBody>
                {taskComments.comments.map((row) => (
                  <TableRow key={row.comment}>
                    <TableCell component="th" scope="row">
                      <small>
                      <Moment locale="sv" fromNow format={"MMMM Do YYYY - HH:mm"}>
                          {row.createdOn && row.createdOn.toDate()}
                        </Moment>
                      </small>
                      <br></br>
                      {row.comment}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={printReport.open}
          onClose={handlePrintClose}
          aria-labelledby="form-dialog-title"
          fullWidth="true"
          maxWidth={"sm"}
        >         
          <div ref={componentRef} style={{ padding: '5px' }}>
            <DialogTitle id="form-dialog-title">
              <Grid container>
                <Grid item xs={6} style={{ textAlign: "left"}}>
                  <b>
                    {printReport.deviation && printReport.deviation.unitName }
                    {printReport.deviation && printReport.deviation.unit }
                  </b>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right"}}>
                  <ReactToPrint
                  trigger={() => <Button variant="contained" color="primary">Skriv ut</Button>}
                  content={() => componentRef.current}
                  />
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>             
              <Grid container>
                <Grid item xs={4} style={{ textAlign: "left"}}>               
                  <p>
                    <small>
                      <b style={classes.headline}>Inkom:</b>
                    </small>
                    <br></br>
                    <DateRange style={{fontSize: 13}} />  <Moment locale="sv" fromNow format={"MMMM Do YYYY"}>
                      {printReport.deviation.raportDate && printReport.deviation.raportDate.toDate()}
                    </Moment>         
                    <br></br>
                    <ClockIcon style={{fontSize: 13}} /> <Moment locale="sv" fromNow format={"HH:mm"}>
                      {printReport.deviation.raportDate && printReport.deviation.raportDate.toDate()}
                    </Moment>                         
                  </p>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "left" }}>
                  <p>
                    <small>
                      <b style={classes.headline}>Användare:</b>
                    </small>
                    <br></br>
                    <Face style={{fontSize: 13}} /> {printReport.deviation.raporter
                      ? printReport.deviation.raporter.replace('"','').replace('"','')
                      : ""}
                    {printReport.deviation.userName
                      ? printReport.deviation.userName.replace('"','').replace('"','')
                      : ""}
                  </p>
                </Grid>
                <Grid item xs={4} style={{ textAlign: "left" }}>
                  <p>
                    <small>
                      <b style={classes.headline}>Gäller:</b>
                    </small>
                    <br></br>
                    {printReport.deviation.unitName
                      ? printReport.deviation.unitName
                      : ""}
                    {printReport.deviation.unit
                      ? printReport.deviation.unit
                      : ""}
                  </p>
                </Grid>
                <Grid item xs={12} style={{ textAlign: "left" }}>
                  <p>
                    <small>
                      <b style={classes.headline}>Rapport:</b>
                    </small>
                    <br></br>
                    {printReport.deviation.elseComments
                      ? printReport.deviation.elseComments
                      : ""}
                    
                    {printReport.deviation.description
                      ? printReport.deviation.description
                      : ""}

                    {printReport.deviation.controlRuleId && getControlResultFields(printReport.deviation.controlRuleId, printReport.deviation)}
                    {printReport.deviation.unitId && getControlResultFields(printReport.deviation.unitId, printReport.deviation)}

                  </p>
                </Grid>
                {printReport.deviation.image && 
                  <Grid item xs={12}>
                    <small style={classes.headline}>Bifogad bild</small><br></br>
                  <img src={printReport.deviation.image} width="200"></img>
                  </Grid>
                }
              </Grid>
             
            </DialogContent>
            <TableContainer style={{ padding: 1 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                      {printReport.comments && printReport.comments.comments.map((row) => (
                      <TableRow key={row.comment}>
                        <TableCell component="th" scope="row">
                          <small>
                          <Moment locale="sv" fromNow format={"MMMM Do YYYY - HH:mm"}>
                              {row.createdOn && row.createdOn.toDate()}
                            </Moment>
                          </small>
                          <br></br>
                          {row.comment}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                 
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Dialog>
      </div>
    </>
  );
};

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
  headline: { 
    color: "grey"
  }
};

const mapStateToProps = (state) => {
  return {
    report: state.report,
    reportCompleted: state.reportCompleted,
    unit: state.unit,
    unitIdCompleted: state.unitIdCompleted
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    listCompleted: (createdOn, type, unitIdCompleted) => dispatch(listCompleted(createdOn, type, unitIdCompleted)),
    listReports: (createdOn, type, unitId) => dispatch(listReports(createdOn, type, unitId)),
    setReportCount: (reportCount, count) => dispatch(setReportCount(reportCount, count)),
    createComment: (request) => dispatch(createComment(request)),
    getDeviationById: (id) => dispatch(getDeviationById(id)),
    getComments: (id) => dispatch(getComments(id)),
    getRaportById: (id) => dispatch(getRaportById(id)),
    getRaportDeviationById: (id) => dispatch(getRaportDeviationById(id)),
    getRaportNotCompleted: (id) => dispatch(getRaportNotCompleted(id)),
    listUnits: () => dispatch(listUnits()),
    error: (request) => dispatch(error(request)),
    getTaskById: request => dispatch(getTaskById(request))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
