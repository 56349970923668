import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { list as listUnit } from "../../store/actions/unitActions";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DialogDefault from "../Shared/dialogDefault";
import TablePagination from "@material-ui/core/TablePagination";

const ListUnitContainer = (props) => {
  useEffect(() => {
    props.listUnit();
  }, []);

  const [dialog, setDialog] = useState({ open: false, id: null });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickCancel = () => {
    setDialog({
      open: false,
      id: null,
    });
  };

  const units = props.unit ? props.unit.list.data : [];
  return (
    <Grid item xs={12} md={12} lg={12}>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
        <h1>Enheter</h1>
        </Grid>
        <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px 0'}}>
        <Button variant="contained" component={Link} to="/unit/create">
          <i className="gg-add"></i> Skapa ny enhet
        </Button>
        </Grid>
      </Grid>

      <Paper style={classes.paper}>
       
        <p>
          Enheter är kylar, frysar etc. som ska kontrolleras. Det behöver dock
          inte vara en fast installation utan det är även dina kontroller styrda
          av kontrollpunkterna.<br></br>
          Vi har skapat enheter för alla kontrollpunkter som du kan använda
          eller editera. Du kan även skapa nya egna enheter.
        </p>

       

        <TableContainer style={{ padding: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Namn
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Beskrivning
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {units
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      <Button size="small"
                        variant="contained"
                        component={Link}
                        to={"/unit/edit/" + row.id}
                      >
                        Redigera
                      </Button>

                      {/* <Fab
                      size="small"
                      color="primary"
                      aria-label="add"
                      component={Link}
                      to={"/unit/edit/" + row.id}
                    >
                      <EditIcon />
                    </Fab> */}
                    </TableCell>
                    <TableCell>
                      <Button size="small"
                        variant="contained"
                        onClick={() => {
                          setDialog({ open: true, id: row.id });
                        }}
                      >
                        Radera
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Rader per sida"
          rowsPerPageOptions={[50, 75, 100]}
          component="div"
          count={units.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <DialogDefault
        module="unit"
        action="delete"
        open={dialog.open}
        id={dialog.id}
        title={"Enheter"}
        message={"Vill du verkligen radera?"}
        handleClickCancel={handleClickCancel}
      />
    </Grid>
  );
};

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
};

const mapStateToProps = (state) => {
  return {
    unit: state.unit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listUnit: () => dispatch(listUnit()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUnitContainer);
