import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { success, error } from "../../store/actions/toasterActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { deleteCustomer as deleteCustomer } from "../../store/actions/customerActions";
import { remove as removeUnit } from "../../store/actions/unitActions";
import { remove as removeControlRule } from "../../store/actions/controlRuleActions";
import { remove as removeTask } from "../../store/actions/taskActions";
import { remove as removeNotificationSettings } from "../../store/actions/notificationSettingsActions";
import { signOut } from "../../store/actions/authActions";
import { deleteTaskAdmin } from "../../store/actions/taskAdminActions";

const styles = () => ({});

class DialogContainer extends Component {
  constructor(props) {
    super(props);
  }

  handleConfirm = () => {
    if (this.props.module === "unit" && this.props.action === "delete") {
      this.props.removeUnit(this.props.id);
    } 
    else if (
      this.props.module === "controlRule" &&
      this.props.action === "delete"
    ) {
      this.props.removeControlRule(this.props.id);
    } 
    else if (
      this.props.module === "customerList" &&
      this.props.action === "delete"
    ) {
      this.props.deleteCustomer(this.props.id);
    } 
    else if (this.props.module === "task" && this.props.action === "delete") {
      this.props.removeTask(this.props.id, this.props.extraProps.unitId);
    } else if (
      this.props.module === "notificationSettings" &&
      this.props.action === "delete"
    ) {
      this.props.removeNotificationSettings(this.props.id);
    } else if (
      this.props.module === "auth" &&
      this.props.action === "signOut"
    ) {
      this.props.signOut();
    } else if (
      this.props.module === "taskAdmin" &&
      this.props.action === "deleteTaskAdmin"
    ) {
      this.props.deleteTaskAdmin(this.props.uid);
    }

    this.handleClose();
  };

  handleClose = () => {
    this.props.handleClickCancel(true);
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Nej
            </Button>
            <Button onClick={this.handleConfirm} color="primary" autoFocus>
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteCustomer: (id) => dispatch(deleteCustomer(id, ownProps)),
    removeUnit: (id) => dispatch(removeUnit(id, ownProps)),
    removeControlRule: (id) => dispatch(removeControlRule(id, ownProps)),
    removeTask: (id, unitId) => dispatch(removeTask(id, unitId)),
    removeNotificationSettings: (id) =>
      dispatch(removeNotificationSettings(id, ownProps)),
    deleteTaskAdmin: (uid) => dispatch(deleteTaskAdmin(uid)),
    signOut: () => dispatch(signOut()),
    success: (request) => dispatch(success(request)),
    error: (request) => dispatch(error(request)),
  };
};

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(DialogContainer))
);
