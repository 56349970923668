import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash/core";
import {
  update as updateControlRule,
  getById
} from "../../store/actions/controlRuleActions";
import { success, error } from "../../store/actions/toasterActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

class EditControlRuleContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: {
        displayName: "Name",
        value: null,
        required: true
      },
      description: {
        displayName: "Description",
        value: null,
        required: true
      }
    };
  }

  componentDidMount = async () => {
    console.log("componentDidMount");
    const id = this.props.match.params.id;
    await this.props.getById(id);
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    if (!_.isEmpty(nextProps.edit.data)) {
      let currentState = this.state;
      currentState.name.value = nextProps.edit.data.name;
      currentState.description.value = nextProps.edit.data.description;
      this.setState(currentState);
    }
  }

  handleChange = e => {
    const currentFieldName = e.target.name;
    const currentFieldValue = e.target.value;
    let currentField = this.state[e.target.name];
    if (currentField) {
      this.setState(previousState => ({
        name: {
          ...previousState.name,
          value:
            currentFieldName === "name"
              ? currentFieldValue
              : previousState.name.value
        },
        description: {
          ...previousState.description,
          value:
            currentFieldName === "description"
              ? currentFieldValue
              : previousState.description.value
        }
      }));
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    const request = this.validateInput();
    console.log("request", request);
    if (!_.isEmpty(request)) {
      this.props.updateControlRule(request);
    }
  };

  validateInput = () => {
    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function(value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false;
      } else {
        request[key] = value.value;
      }
    });

    return isValid ? request : {};
  };

  render() {
    const { classes } = this.props;
    const controlRule = this.props.edit.data;
    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit} autoComplete="off">
            <h1>Redigera {controlRule.name}</h1>
            <Grid item xs={12} lg={6}>
              <h3>Detaljer</h3>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                name="name"                                  
                autoFocus
                onChange={this.handleChange}
                value={this.state.name.value}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="description"
                multiline={true}
                name="description"                        
                onChange={this.handleChange}
                value={this.state.description.value}
              />

              <Button
                component={Link} variant="contained" to={"/controlRule/list"}                                 
                className={classes.submit}
              >
                Avbryt
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained" 
                type="submit"                        
                className={classes.submit}
              >
                Uppdatera
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}


const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    paddingTop: '1px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    color: "black",
  },
});

const mapStateToProps = state => {
  return {
    edit: state.controlRule.edit
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getById: id => dispatch(getById(id, ownProps)),
    updateControlRule: request =>
      dispatch(updateControlRule(request, ownProps)),
    success: request => dispatch(success(request)),
    error: request => dispatch(error(request))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EditControlRuleContainer))
);
