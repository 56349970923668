import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import { updateTask } from "../../store/actions/taskActions";
import { success, error } from "../../store/actions/toasterActions";
import { listTaskAdmin } from "../../store/actions/taskAdminActions";
import { list as listUnit } from "../../store/actions/unitActions";
import { list as listControlRule } from "../../store/actions/controlRuleActions";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox'
import DateFnsUtils from "@date-io/date-fns"; // import
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

import moment from "moment"
import MomentUtils from "@date-io/moment"
import svLocale from "date-fns/locale/sv"

const localeMap = {
  sv: svLocale,
}

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
    color: "black",
  },
});

class EditTask extends Component {

  constructor(props) {
  
    super(props)

    this.state = {
      //editorState: EditorState.createEmpty(),
      locale: 'sv',
      name: {
        displayName: "Name",
        value: this.props.task.name,
        required: true,
      },
      description: {
        displayName: "Description",
        value: this.props.task.description,
        required: true,
      },
      startDate: {
        displayName: "Start Date",
        value: this.props.task.startDate,
        required: true,
      },
      endDate: {
        displayName: "End Date",
        value: this.props.task.endDate,
        required: true,
      },
      taskAdminId: {
        displayName: "Task Admin",
        value: this.props.task.taskAdminId,
        required: true,
      },
      unitId: {
        displayName: "Unit",
        value: this.props.task.unitId,
        required: true,
      },
      controlRuleId: {
        displayName: "Control Rule",
        value: this.props.task.controlRuleId,
        required: true,
      },
      controlRuleMin: {
        displayName: "Min Value",
        value: this.props.task.controlRuleMin,
        required: false,
      },
      controlRuleMax: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMax,
        required: false,
      },
      controlRuleMinCold: {
        displayName: "Min Value",
        value: this.props.task.controlRuleMinCold,
        required: false,
      },
      controlRuleMaxCold: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMaxCold,
        required: false,
      },

      controlRuleMinHot: {
        displayName: "Min Value",
        value: this.props.task.controlRuleMinHot,
        required: false,
      },
      controlRuleMaxHot: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMaxHot,
        required: false,
      },

      controlRuleMinFrozen: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMinFrozen,
        required: false,
      },
      controlRuleMaxFrozen: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMaxFrozen,
        required: false,
      },

      controlRuleMinColdFishFridge: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMinColdFishFridge,
        required: false,
      },

      controlRuleMaxColdFishFridge: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMaxColdFishFridge,
        required: false,
      },

      controlRuleMinColdFridge: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMinColdFridge,
        required: false,
      },

      controlRuleMaxColdFridge: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMaxColdFridge,
        required: false,
      },

      controlRuleMinColdFood: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMinColdFood,
        required: false,
      },

      controlRuleMaxColdFood: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMaxColdFood,
        required: false,
      },

      controlRuleMinColdHotFood: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMinColdHotFood,
        required: false,
      },

      controlRuleMaxColdHotFood: {
        displayName: "Max Value",
        value: this.props.task.controlRuleMaxColdHotFood,
        required: false,
      },

      controlRuleFrozenIn: {
        displayName: "Max Value",
        value: this.props.task.controlRuleFrozenIn,
        required: false,
      },

      controlRuleTwoHours: {
        displayName: "Max Value",
        value: this.props.task.controlRuleTwoHours,
        required: false,
      },

      controlRuleFourHours: {
        displayName: "Max Value",
        value: this.props.task.controlRuleFourHours,
        required: false,
      },

      daily: {
        displayName: "Daily",
        value: this.props.task.daily,
        required: false,
      },
      weekly: {
        displayName: "Weekly",
        value: this.props.task.weekly,
        required: false,
      },
      monthly: {
        displayName: "Monthly",
        value: this.props.task.monthly,
        required: false,
      },
      quarter: {
        displayName: "Quarter",
        value: false,
        required: false,
      },
      eachtwomonths: {
        displayName: "EachTwoMonths",
        value: false,
        required: false,
      },
      monday:{
        displayName: "Monday",
        value: this.props.task.monday,
        required: false,
      },
      tuesday:{
        displayName: "Tuesday",
        value: this.props.task.tuesday,
        required: false,
      },
      wednesday:{
        displayName: "Wednesday",
        value: this.props.task.wednesday,
        required: false,
      },
      thursday:{
        displayName: "Thursday",
        value: this.props.task.thursday,
        required: false,
      },
      friday:{
        displayName: "Friday",
        value: this.props.task.friday,
        required: false,
      },
      saturday:{
        displayName: "Saturday",
        value: this.props.task.saturday,
        required: false,
      },
      sunday:{
        displayName: "Sunday",
        value: this.props.task.sunday,
        required: false,
      },
      repeatId:{
        displayName: "Sunday",
        value: this.props.task.repeatId,
        required: false,
      },
      customRepeat: {
        displayName: "Sunday",
        value: this.props.task.customRepeat,
        required: false,
      },
      monthRepeat: {
        displayName: "Sunday",
        value: this.props.task.monthRepeat,
        required: false,
      }      ,
      yearRepeat: {
        displayName: "Sunday",
        value: this.props.task.yearRepeat,
        required: false,
      } ,
      weakRepeat: {
        displayName: "Sunday",
        value: this.props.task.weakRepeat,
        required: false,
      },
      dayRepeat: {
        displayName: "Sunday",
        value: this.props.task.dayRepeat,
        required: false,
      },
      endRepetition:{
        displayName: "End repetition",
        value: this.props.task.endRepetition,
        required: false
      }

    };

    this.handleCalStartChange = this.handleCalStartChange.bind(this)
    this.handleCalEndChange = this.handleCalEndChange.bind(this)

  }

  componentDidMount() {

    this.props.listTaskAdmin();
    this.props.listUnit();
    this.props.listControlRule();

    if(this.props.task){
      this.setState({
        "startDate": this.props.task.startDate,
      })   
      this.setState({
        "endDate": this.props.task.endDate,
      }) 
    }
      

    console.log("this.props.task.overDelegated", this.props.task.overDelegated)

  }

  handleChange = (e) => {
    
    console.log("e.target.value", e.target.value)
    console.log("e.target.name", e.target.name)
   

    if (!e.target) return true
    let currentField = this.state[e.target.name]

    if (currentField) {

      if (e.target.type === "checkbox") {
        currentField.value = e.target.checked;
      } else {
        currentField.value = e.target.value;
      }

      this.setState({[e.target.name]: currentField})

    }

  }


  handleSubmit = async event => {

    event.preventDefault();
    
    const request = this.validateInput();
    
    if (!_.isEmpty(request)) {

      let startDate, endDate
      if(this.state.startDate){
        if(this.state.startDate.seconds){        
          startDate = moment(this.toDateTime(this.state.startDate.seconds)).format("yyyy/MM/DD 07:00")       
        }        
        else
        {
          startDate =  moment(this.state.startDate).format("yyyy/MM/DD 07:00") 
        }
      }

      if(this.state.endDate){
        if(this.state.endDate.seconds){        
          endDate = moment(this.toDateTime(this.state.endDate.seconds)).format("yyyy/MM/DD 07:00")       
        }        
        else
        {
          endDate =  moment(this.state.endDate).format("yyyy/MM/DD 07:00") 
        }
      }

      let tempStamp = moment("1980-01-01").format("yyyy/MM/DD 07:00")

      console.log("this.state.weakRepeat", this.state.weakRepeat )

      if(this.state.weakRepeat.value == ""){
        request["weakRepeat"] = null
      }

      request["startDate"] = new Date(startDate)
      request["endDate"]  = new Date(endDate)
      request["endRepetition"]  = this.state.endRepetition.value
      request["lastNotificationDate"]  = new Date(tempStamp)
      this.props.updateTask(request);
    }
  };

  validateInput = () => {

    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function(value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false
      
      } else if( _.includes(["daily", "weekly", "monthly", "quarter", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"], key) ||  !_.isEmpty(value.value)){
         request[key] = value.value;
      // }else {
      //   if(key == "name"){
      //     request[key] = value.value;
      //   }else{
      //     request[key] = value;
      //   }
      }
    });

    return isValid ? request : {};
  }

  handleCalStartChange(e)  {
    this.setState({startDate: e})   
  }

  handleCalEndChange(e)  {
    this.setState({"endDate": e})   
   
  }


  getControlRulesInput(controlRuleId, task, that, handleChange){
    if(
      controlRuleId === "vpsmWbFCtmKIAvDoIJZu"
      || controlRuleId === "O9BQfuhYxbPNqBkUgznX"
      || controlRuleId === "7n8E1hN17c5Cz15DELCd"
      || controlRuleId === "wFvh2AAmY3B2IvZY25AU"
      || controlRuleId === "HWrn1AD66BmsteRtKIO3"
      || controlRuleId === "DjqOZcYxxHWBl5F3O5cS"
      || controlRuleId === "KoeiG9iYHfjQnZHDl0st"
      || controlRuleId === "PK5LoXdp7jOq9IykSU3b"
      || controlRuleId === "F0zFhYA1HrlIeVXz6wBs"
      ){
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Gränsvärde får ej överstiga (ange som -10 för minusgrader och 60 för plusgrader)"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={handleChange}
          defaultValue={task.controlRuleMin}
        />
       )
    }

    if(
      controlRuleId === "6LwHq1LqNmxm3u5kdyPF"
      || controlRuleId === "fCNqBtAPGD3eJWOmELxP"
      || controlRuleId === "EW3Zr5ZwdypZ8xEILBHF"
      || controlRuleId === "FtQZdnpPR8tWPKCp2rqz"
      ){
      return (
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Gränsvärde måste överstiga (ange som -10 för minusgrader och 60 för plusgrader)"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={that.handleChange}
          defaultValue={task.controlRuleMin}
        />
       )
    }

    if(
      controlRuleId === "DMGRi5qiG80qHU3VQSeh"      
      ){
      return (<>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Isvatten måste understiga"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={handleChange}
          defaultValue={task.controlRuleMin}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMax"
          name="controlRuleMax"
          label="Kokande vatten måste överstiga"
          autoComplete="controlRuleMax"
          autoFocus
          onChange={that.handleChange}
          defaultValue={task.controlRuleMax}
        />
       </>)
    }

    if(
      controlRuleId === "irdQkcnWcj1TaN3QUwnT"      
      ){
      return (<>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMin"
          name="controlRuleMin"
          label="Gränsvärde, Temperaturkontroll 2 timmar (ange som -10)"
          autoComplete="controlRuleMin"
          autoFocus
          onChange={that.handleChange}
          defaultValue={task.controlRuleMin}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="controlRuleMax"
          name="controlRuleMax"
          label="Gränsvärde, Temperaturkontroll 4 timmar (ange som -10)"
          autoComplete="controlRuleMax"
          autoFocus
          onChange={that.handleChange}
          defaultValue={task.controlRuleMax}
        />
       </>)
    }
  }

  toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  render() {
    
    const { classes } = this.props;
    const taskAdmins = this.props.taskAdmins ? this.props.taskAdmins.data : [];
    const units = this.props.unit ? this.props.unit.data : [];
    const controlRules = this.props.controlRule ? this.props.controlRule.data : [];
    const { controlRuleId, controlRuleMin, controlRuleMax, unitId, taskAdminId, name, description, endRepetition, repeatId, customRepeat, dayRepeat, weakRepeat, monday, tuesday, wednesday, thursday, friday, saturday, sunday, monthly, yearRepeat} = this.state;

    
    let startDate
    if(this.state.startDate){
      if(this.state.startDate.seconds){        
        startDate = moment(this.toDateTime(this.state.startDate.seconds)).format("YYYY-MM-DD")       
      }        
      else
      {
        startDate =  moment(this.state.startDate).format("YYYY-MM-DD") 
      }
    }


    let endDate
    if(this.state.endDate){
      if(this.state.endDate.seconds){        
        endDate = moment(this.toDateTime(this.state.endDate.seconds)).format("YYYY-MM-DD")   
      }        
      else{
        endDate = moment(this.state.endDate).format("YYYY-MM-DD") 
      }
    }

   

    let ruleId = controlRuleId.value && controlRuleId.value

    console.log("ruleId", ruleId)

    let showOne = false
    if(
      ruleId === "vpsmWbFCtmKIAvDoIJZu"
      || ruleId === "O9BQfuhYxbPNqBkUgznX"
      || ruleId === "7n8E1hN17c5Cz15DELCd"
      || ruleId === "wFvh2AAmY3B2IvZY25AU"
      || ruleId === "HWrn1AD66BmsteRtKIO3"
      || ruleId === "DjqOZcYxxHWBl5F3O5cS"
      || ruleId === "KoeiG9iYHfjQnZHDl0st"
      || ruleId === "PK5LoXdp7jOq9IykSU3b"
      || ruleId === "F0zFhYA1HrlIeVXz6wBs"
      ){
        showOne = true
      }

      let showTwo = false
      if(
        ruleId === "6LwHq1LqNmxm3u5kdyPF"
        || ruleId === "fCNqBtAPGD3eJWOmELxP"
        || ruleId === "EW3Zr5ZwdypZ8xEILBHF"
        || ruleId === "FtQZdnpPR8tWPKCp2rqz"
        ){
          showTwo = true
      }
      
      let showThree = false
      if(
        ruleId === "DMGRi5qiG80qHU3VQSeh"      
        ){        
          showThree = true
      }
      
      let showFour = false
      if(
        ruleId === "irdQkcnWcj1TaN3QUwnT"      
        ){    
          showFour = true    
      }

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form
            className={classes.form}
            onSubmit={this.handleSubmit}
            style={{ marginTop: 0 }}
          >
            <h1>Skapa arbetsuppgift</h1>
            <p>
              Här uppdaterar du dina arbetsuppgifter som du sedan delegerar till dina
              medarbetare, och kanske dig själv. Tidsbestämma samt i vilket
              intervall arbetsuppgifterna ska genomföras.<br></br>
              <br></br>
              Välj kontrollpunkt samt vilken enhet som ska kontrolleras. Skriv
              en kort beskrivning om det behövs, man kanske behöver anvisa var
              en viss enhet är lokaliserad. Välj i vilket intervall enheten ska
              kontrolleras samt startdatum för kontrollen samt ett slutdatum om
              det är aktuellt. <br></br>
              <br></br>
              När detta är gjort så tilldelar du arbetsuppgiften till en
              användare.<br></br>
              <br></br>
              Exempel : Du väljer Ankomstkontroll och sedan Enhet. Enheten som
              ska kontrolleras är Varuleverans kyld. Om du vill kan du skriva en
              kort beskrivning som t.ex. Varorna står på en bur vid hissen. Du
              kan även redigera titeln på uppgiften.<br></br>
              <br></br>
              Du delegerar detta till en användare i din lista över medarbetare.
              Om den du har gett detta till inte kan lösa sin arbetsuppgift så
              kan hen delegera till valfri person i användarlistan. Sätt ett
              datum när den första arbetsuppgiften ska genomföras. Om det finns
              ett slutdatum för arbetsuppgiften så anger du även det.
            </p>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={4} spacing={3}>
              <h3>Välj kontrollpunkt</h3>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="controlRuleLabel">
                    Kontrollpunkter
                  </InputLabel>
                  <Select
                    variant="outlined"
                    id="controlRuleId"
                    name="controlRuleId"
                    labelId="ruleIdLabelId"
                    label="Custom Value"
                    labelWidth={80}
                    defaultValue={controlRuleId.value}
                    onChange={this.handleChange}
                  >
                    {controlRules.map((rule) => (
                      <MenuItem value={rule.id} key={rule.id}>
                        {rule.name}
                      </MenuItem>
                    ))}
                  </Select>


                  {showOne
                  &&
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="controlRuleMin"
                      name="controlRuleMin"
                      label="Gränsvärde får ej överstiga (ange som -10 för minusgrader och 60 för plusgrader)"
                      autoComplete="controlRuleMin"
                      autoFocus
                      onChange={this.handleChange}
                      defaultValue={controlRuleMin.value}
                    />
                  
                  }

                  {showTwo
                  &&
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="controlRuleMin"
                      name="controlRuleMin"
                      label="Gränsvärde måste överstiga (ange som -10 för minusgrader och 60 för plusgrader)"
                      autoComplete="controlRuleMin"
                      autoFocus
                      onChange={this.handleChange}
                      defaultValue={controlRuleMin.value}
                    />                 
                  }

                  {showThree      
                  && 
                  <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="controlRuleMin"
                      name="controlRuleMin"
                      label="Isvatten måste understiga"
                      autoComplete="controlRuleMin"
                      autoFocus
                      onChange={this.handleChange}
                      defaultValue={controlRuleMin.value}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="controlRuleMax"
                      name="controlRuleMax"
                      label="Kokande vatten måste överstiga"
                      autoComplete="controlRuleMax"
                      autoFocus
                      onChange={this.handleChange}
                      defaultValue={controlRuleMax.value}
                    />
                  </>
                }

                {showFour      
                && <>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="controlRuleMin"
                      name="controlRuleMin"
                      label="Gränsvärde, Temperaturkontroll 2 timmar (ange som -10)"
                      autoComplete="controlRuleMin"
                      autoFocus
                      onChange={this.handleChange}
                      defaultValue={controlRuleMin.value}
                    />

                    <TextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="controlRuleMax"
                      name="controlRuleMax"
                      label="Gränsvärde, Temperaturkontroll 4 timmar (ange som -10)"
                      autoComplete="controlRuleMax"
                      autoFocus
                      onChange={this.handleChange}
                      defaultValue={controlRuleMax.value}
                    />
                  </>}

                   {/* {this.getControlRulesInput(task.controlRuleId, task, this, this.handleChange)} */}


                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4}>
              <h3>Välj enhet att kontrollera</h3>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="unitLabel">Enheter</InputLabel>
                  <Select
                    variant="outlined"
                    id="unitId"
                    name="unitId"
                    labelId="unitLabel"
                    label="unit"
                    labelWidth={80}
                    defaultValue={unitId.value}
                    onChange={this.handleChange}
                  >
                    {units.map((unit) => (
                      <MenuItem value={unit.id} key={unit.id}>
                        {unit.name} - {unit.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={4}>
                <h3>Tilldela en ansvarig</h3>

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="countryLabel">Användare</InputLabel>
                  <Select
                    variant="outlined"
                    id="taskAdminId"
                    name="taskAdminId"
                    labelId="taskAdminLabel"
                    label="taskAdmin"
                    labelWidth={80}
                    onChange={this.handleChange}
                    defaultValue={taskAdminId.value}
                  >
                    {taskAdmins.map((user) => (
                      <MenuItem value={user.id} key={user.id}>
                        {user.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6}>
                <h3>Detaljer</h3>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  name="name"
                  label="Titel på uppgiften"
                  autoComplete="name"
                  autoFocus
                  onChange={this.handleChange}
                  defaultValue={name.value}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="description"
                  multiline={true}
                  name="description"
                  label="Beskrivning utav uppgiften - tryck enter för radbrytning/ny rad."
                  onChange={this.handleChange}
                  defaultValue={description.value}
                />

                </Grid>
                
                <Grid item xs={12} lg={6}>

                <h3>Frekvens för uppgiften</h3>
                <FormGroup>

                <p>Startar</p>

                {/* <MuiPickersUtilsProvider 
                        libInstance={moment} 
                        utils={DateFnsUtils} 
                        locale={localeMap['sv']}>

                          <DatePicker id="fromDate" 
                          disablePast={false}
                          format="yyyy-MMM-d"           
                          name="fromDate" value={fromDate}
                          onChange={this.handleCalStartChange} />

                        </MuiPickersUtilsProvider>           */}

                <MuiPickersUtilsProvider 
                libInstance={moment} 
                utils={DateFnsUtils} 
                locale={localeMap['sv']}>
                
                  <DatePicker id="startDate" 
                  disablePast={false}
                  format="yyyy MMM d"
                  name="startDate" 
                  value={startDate}
                  maxDateMessage="Datum för stor"
                  minDateMessage="Datum för liten"
                  onChange={this.handleCalStartChange} />

                </MuiPickersUtilsProvider>            

                <p>Slutar</p>          
                <MuiPickersUtilsProvider 
                libInstance={moment} 
                utils={DateFnsUtils} 
                locale={localeMap[this.state.locale]}>

                  <DatePicker  id="endDate" invalidDateMessage=""
                   format="yyyy MMM d"   
                  disablePast={false}
                  maxDateMessage="Datum för stor"
                  minDateMessage="Datum för liten"
                  name="endDate" value={endDate} onChange={this.handleCalEndChange} />

                </MuiPickersUtilsProvider>
                
                



                </FormGroup>
              </Grid>

              <Grid item xs={12} lg={6}>
           
               
              <h4>Repetition för uppgiften</h4>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="unitLabel">Repeteras</InputLabel>
                  
                  {repeatId && <Select
                    variant="outlined"
                    id="repeatId"
                    name="repeatId"
                    labelId="repeatLabel"
                    label="repeatId"
                    labelWidth={80}
                    defaultValue={repeatId.value || ''}
                    onChange={this.handleChange}
                  >    
                    <MenuItem value={"1"} key={"1"}>Varje dag</MenuItem>
                    <MenuItem value={"2"} key={"2"}>Varje vecka ( på dagen för vald datum )</MenuItem>
                    <MenuItem value={"3"} key={"3"}>Varje månad ( på datumet varje månad )</MenuItem>
                    <MenuItem value={"4"} key={"4"}>Varje år ( på datumet varje månad och varje år )</MenuItem>
                    <MenuItem value={"5"} key={"5"}>Anpassad</MenuItem>
                    ))
                  </Select>}

                </FormControl>

                {repeatId && repeatId.value == "5" && 
                  <>
                   <h4>Anpassad repetition</h4>
                   <Select
                    variant="outlined"
                    id="customRepeat"
                    name="customRepeat"
                    labelId="customRepeat"
                    label="customRepeat"
                    labelWidth={80}
                    defaultValue="0"
                    defaultValue={customRepeat.value ? customRepeat.value : 0}
                    onChange={this.handleChange}
                  >    
                    <MenuItem value={"0"} key={"0"}>Välj frekvens</MenuItem>
                    {/* <MenuItem value={"1"} key={"1"}>Daglig</MenuItem> */}
                    <MenuItem value={"2"} key={"2"}>Veckovis</MenuItem>
                    {/* <MenuItem value={"3"} key={"3"}>Månadsvis</MenuItem>
                    <MenuItem value={"4"} key={"4"}>Årsvis</MenuItem> */}
                    ))}
                  </Select>

                    {customRepeat.value == "1" && 
                    <>
                    <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="dayRepeat"
                              multiline={true}
                              name="dayRepeat"
                              label="Varje X dag(ar)"
                              defaultValue={dayRepeat.value}
                              onChange={this.handleChange}
                            />

                    </>
                    }

                    {customRepeat.value === "2" && 
                    <>
                        <p>Ange för intervall. 1 varannan vecka, 2 var tredje vecka, 3 var fjärde vecka osv.</p>
                        <TextField
                                  variant="outlined"
                                  margin="normal"
                                  fullWidth
                                  id="weakRepeat"
                                  multiline={true}
                                  name="weakRepeat"
                                  defaultValue={weakRepeat.value}
                                  label="Varje X vecka(or)..."
                                  onChange={this.handleChange}
                                />
                        <p>på följande dagar</p>
                        <FormControlLabel
                            control={<Checkbox checked={monday.value} onChange={this.handleChange} name="monday" />}
                            label="Måndag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={tuesday.value} onChange={this.handleChange} name="tuesday" />}
                            label="Tisdag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={wednesday.value} onChange={this.handleChange} name="wednesday" />}
                            label="Onsdag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={thursday.value} onChange={this.handleChange} name="thursday" />}
                            label="Torsdag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={friday.value} onChange={this.handleChange} name="friday" />}
                            label="Fredag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={saturday.value} onChange={this.handleChange} name="saturday" />}
                            label="Lördag"
                          />
                        <FormControlLabel
                            control={<Checkbox checked={sunday.value} onChange={this.handleChange} name="sunday" />}
                            label="Söndag"
                          />
               

                    </>
                    }

                  {customRepeat.value === "3" && 
                    <>
                    <TextField
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="description"
                              multiline={true}
                              name="monthRepeat"
                              defaultValue={monthly.value}
                              label="Varje X månad(er)"
                              onChange={this.handleChange}
                            />

                  
                    </>
                    }

                  {customRepeat.value === "4" && 
                    <>
                    <TextField
                               defaultValue={yearRepeat.value}
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              id="description"
                              multiline={true}
                              name="yearRepeat"
                              label="Varje X år"
                              onChange={this.handleChange}
                            />

                  
                    </>
                    }



                  </>
                  }
              
              <h4>Stoppa repetition</h4>

                <Select
                    variant="outlined"
                    id="endRepetition"
                    name="endRepetition"
                    labelId="endRepetition"
                    label="endRepetition"
                    labelWidth={80}
                    defaultValue={endRepetition.value}
                    onChange={this.handleChange}
                  >    
                    <MenuItem value={"0"} key={"0"}>Aldrig</MenuItem>
                    <MenuItem value={"1"} key={"1"}>På slut datumet</MenuItem>
                   

                  </Select>
                {/* <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="startDate"
                  name="startDate"
                  label="Start Date"
                  type="date"
                  onChange={this.handleChange}
                  defaultValue={task.startDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="endDate"
                  name="endDate"
                  label="End Date"
                  type="date"
                  onChange={this.handleChange}
                  defaultValue={task.endDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> */}
              </Grid>

              <Grid item xs={12} lg={6}>

              

              </Grid>

              <Grid item xs={12} lg={6}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  Spara arbetsuppgift
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}
const mapStateToProps = state => {
  return {
    taskAdmins: state.taskAdmin.list,
    unit: state.unit.list,
    controlRule: state.controlRule.list,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateTask: request => dispatch(updateTask(request, ownProps)),
    listTaskAdmin: () => dispatch(listTaskAdmin()),
    listUnit: () => dispatch(listUnit()),
    listControlRule: () => dispatch(listControlRule()),
    success: request => dispatch(success(request)),
    error: request => dispatch(error(request))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditTask))
);
