import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Grid from "@material-ui/core/Grid";
import { list as listControlRule, listMine as listControlRuleMine } from "../../store/actions/controlRuleActions";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import DialogDefault from "../Shared/dialogDefault";
import TablePagination from "@material-ui/core/TablePagination";

const ListControlRuleContainer = props => {

  useEffect(() => {
    props.listControlRule();
    // props.listControlRuleMine();
  }, []);

  const controlRule = props.controlRule ? props.controlRule.list.data : []
  // const controlRuleMine = props.controlRuleMine ? props.controlRuleMine.listMine.data : []

  const [dialog, setDialog] = useState({ open: false, id: null })
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickCancel = () => {
    setDialog({
      open: false,
      id: null
    });
  }

  return (

   

    <Grid item xs={12} md={12} lg={12}>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
          <h1>Kontrollpunkter</h1>
        </Grid>
        <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px 0'}}>
          <Button variant="contained" component={Link} to="/controlRule/create">
            <i className="gg-add"></i> Skapa ny kontrollpunkt
          </Button>
        </Grid>
      </Grid>

      <Paper style={classes.paper}>
            
        
        <p>
        Kontrollpunkter är alla de kontrollpunkter som skall finnas i ett egenkontrollprogram samt att du kan skapa egna kontrollpunkter. <br></br>
Du använder de kontrollpunkter som är aktuella i din verksamhet. Har du ingen catering så kommer du inte att behöva använda den kontrollpunkten.<br></br>
Vill du skapa en egen kontrollpunkt så är det väldigt enkelt när du väl förstår hur vi har byggt systemet. <br></br>
        </p>

        

        <TableContainer style={{ padding: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 10}}>
                  Namn
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Beskrivning
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

           

              {controlRule.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                
                <TableRow key={row.id}>

                  <TableCell component="th" scope="row">
                  {props.auth.user.role==="CustomerAdmin" && 
                    row.customerId === props.auth.user.customerId &&
                    <i className="gg-girl" alt="Skapad utav dig"></i> 
                    } 
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>

                  <TableCell>{row.description}</TableCell>

                  {props.auth.user.role==="SuperAdmin" && 
                     <TableCell>
                       <Button variant="contained" size="small"       
                         onClick={() => {
                           setDialog({open:true, id: row.id });
                         }}>Radera</Button>
                         &nbsp;&nbsp;
                          <Button size="small" component={Link} variant="contained" to={"/controlRule/edit/" + row.id}>Redigera</Button>
                     </TableCell>
                  }
                  
                  
                  {props.auth.user.role==="CustomerAdmin" && 
                    row.customerId === props.auth.user.customerId &&
                      <TableCell>
                       
                        <Button variant="contained" size="small"      
                          onClick={() => {
                            setDialog({open:true, id: row.id });
                          }}>Radera</Button>
                          &nbsp;&nbsp;
                            <Button size="small" component={Link} variant="contained" to={"/controlRule/edit/" + row.id}>Redigera</Button>
                      </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          labelRowsPerPage="Rader per sida"
          rowsPerPageOptions={[10,20, 50]}
          component="div"
          count={controlRule.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <DialogDefault
        module="controlRule"
        action="delete"
        open={dialog.open}
        id={dialog.id}
        title={"Kontrollenhet"}
        message={"Vill du verkligen radera?"}
        handleClickCancel={handleClickCancel}
      />
    </Grid>
  );
};

const classes = {
  root: {
    flexGrow: 1
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black"
  }
};

const mapStateToProps = state => {
  return {
    controlRule: state.controlRule,
    controlRuleMine: state.controlRuleMine,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    listControlRule: () => dispatch(listControlRule()),
    listControlRuleMine: () => dispatch(listControlRuleMine())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListControlRuleContainer);
