import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router-dom";

class HomeContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.firebaseAuth.uid) return <Redirect to="/signin" />;
    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper style={classes.paper}>
          <h1>Välkommen {this.state}</h1>
          
<p>Vi har skapat ett verktyg för att underlätta för dig att utföra dina egenkontroller och att de verkligen utförs. Här kan du skapa arbetsuppgifter för att sedan delegera till dina medarbetare. 
Tanken är att sprida arbetsuppgifterna bland medarbetarna. Den som plockar varor är den som utför ankomstkontroller.</p>
<p>Under fliken kontrollpunkter hittar du alla de kontrollpunkter som skall finnas i ett egenkontrollprogram samt att du kan skapa egna kontrollpunkter. Under fliken enheter skapar du sedan dina enheter som skall kontrolleras såsom kylar och frysar. </p>
<p>Alla som sedan ska utföra kontroller inklusive dig själv registrerar du sedan under fliken användare. 
Mer detaljerade instruktioner kommer du att hitta under respektive flik.</p>

          <p>Välkommen till EGNCTRL</p>
        </Paper>
      </Grid>
    );
  }
}

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    firebaseAuth: state.firebase.auth,
  };
};

export default connect(mapStateToProps, null)(HomeContainer);
