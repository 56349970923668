export const create = (request, ownProps) => {
    return (dispatch, getState, { getFirestore }) => {
      const firestore = getFirestore();
      const auth = getState().auth;
  
      firestore
        .collection("notificationSettings")
        .add({
          ...request,
          createdOn: new Date(),
          createdBy: auth.user.uid
        })
        .then(function(response) {
          dispatch({
            type: "NOTIFICATION_SUCCESS",
            payload: "Notifikation inställning skapad!"
          });
  
          dispatch({
            type: "CREATE_UNIT_SUCCESS"
          });
  
          ownProps.history.push("/notificationSettings/list");
        })
        .catch(error => {
          console.log("errors==> " + error.message);
          dispatch({
            type: "NOTIFICATION_FAILED",
            payload: "Create notification settings failed!"
          });
        });
    };
  };


export const getById = (id, ownProps) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      firestore
        .collection("notificationSettings")
        .doc(id)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            dispatch({
              type: "FETCH_NOTIFICATION-SETTING_SUCCESS",
              payload: { id: doc.id, ...doc.data() }
            });
          } else {
            dispatch({ type: "FETCH_CUSTOMER_FAILED", payload: {} });
          }
        })
        .catch(function(error) {
          console.log("Error getting documents: ", error);
          dispatch({ type: "FETCH_NOTIFICATION-SETTING_FAILED", payload: [] });
        });
    };
  };
  
  
  export const update = (request, ownProps) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      const auth = getState().firebase.auth;
      let { id, ...notificationSetting } = getState().notificationSettings.edit.data;
      Object.assign(notificationSetting, request);
      notificationSetting.updatedOn = new Date();
      notificationSetting.updatedBy = auth.uid;
  
      firestore
        .collection("notificationSettings")
        .doc(id)
        .update(notificationSetting)
        .then(function(doc) {
          dispatch({
            type: "NOTIFICATION_SUCCESS",
            payload: "Notifikation uppdaterad!"
          });
          ownProps.history.push("/notificationSettings/list");
        })
        .catch(function(error) {
          console.log("Error Notification settings update: ", error);
          dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
        });
    };
  };

  
  export const list = () => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      const auth = getState().auth;
      firestore
        .collection("notificationSettings")
        .where("customerId", "==", auth.user.customerId)
        //.orderBy("name")
        .get()
        .then(function(querySnapshot) {
          let units = [];
          querySnapshot.forEach(function(doc) {
            units.push({
              id: doc.id,
              ...doc.data()
            });
          });
          dispatch({ type: "FETCH_NOTIFICATION-SETTINGS_SUCCESS", payload: units });
        })
        .catch(function(error) {
          console.log("Error getting units : ", error);
          dispatch({ type: "FETCH_NOTIFICATION-SETTINGS_FAILED", data: [] });
        });
    };
  };

  export const remove = (id, ownProps) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      console.log("id",id);
      firestore
        .collection("notificationSettings")
        .doc(id)
        .delete()
        .then(function(doc) {
          dispatch({
            type: "NOTIFICATION_SUCCESS",
            payload: "Notification raderad!"
          });
          dispatch(list());
        })
        .catch(function(error) {
          console.log("Error Notification settings delete: ", error);
          dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
        });
    };
  };
  