import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash/core";
import { updateUser } from "../../store/actions/authActions";
import { success, error } from "../../store/actions/toasterActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';

class MyAccountContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: {
        displayName: "Display Name",
        value: props.auth.user.displayName,
        required: true,
      },
      password: {
        displayName: "Password",
        value: null,
        required: false,
      },
      confirmPassword: {
        displayName: "Confirm Password",
        value: null,
        required: false,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    if (!_.isEmpty(nextProps.auth.user)) {
      let currentState = this.state;
      currentState.displayName.value = nextProps.auth.user.displayName;
      this.setState(currentState);
    }
  }

  handleChange = (e) => {
    let currentField = this.state[e.target.name];
    if (currentField) {
      currentField.value = e.target.value;

      this.setState({
        [e.target.name]: currentField,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const request = this.validateInput();
    if (!_.isEmpty(request)) {
      //console.log(request);
      this.props.updateUser(request);
    }
  };

  validateInput = () => {
    const _this = this;

    if (!this.state.displayName.value) {
      _this.props.error(`${this.state.displayName.displayName} är obligatorisk`);
      return {};
    }

    if (this.state.password.value !== this.state.confirmPassword.value) {
      _this.props.error(`Password and Confirm password should match`);
      return {};
    }

    return {
      uid:this.props.auth.user.uid,
      displayName: this.state.displayName.value,
      password: this.state.password.value,
    };
  };

  render() {
    const { classes } = this.props;

    let licens = sessionStorage.licensType
    if(licens == "class1"){
      licens = "1-3 användare"
    }
    if(licens == "class2"){
      licens = "4-6 användare"
    }
    if(licens == "class3"){
      licens = "7-10 användare"
    }
    if(licens == "class4"){
      licens = "11-20 användare"
    }

    return (
      <Grid item xs={12} md={12} lg={12}>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
        <h1>Mitt konto</h1>
        </Grid>
        <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px 0'}}>
        
        </Grid>
      </Grid>

        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
           
            <p>
            Detaljer kring ditt konto hos EGNCTRL.<br></br>
            Här kan du byta ditt lösenord samt se hur ditt abonnemang löper samt hur många användare ditt avtal omfattar.

            </p>

            <p>
              <b>Din licens är av typen {licens} och är giltigt till och med {sessionStorage.subscriptionValidUntil}</b>
            </p>

            <Grid item xs={12} lg={6}>
              {/* <h3>Detaljer</h3> */}
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="displayName"
                name="displayName"
                label="Namn"
                autoComplete="name"
                autoFocus
                onChange={this.handleChange}
                value={this.state.displayName.value}
              />

              <p>Byt lösenord</p>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="password"
                name="password"
                label="Lösenord"
                type="password"
                onChange={this.handleChange}
              />
              
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                label="Bekräfta lösenord"
                type="password"
                onChange={this.handleChange}
              />
              &nbsp;&nbsp;&nbsp;
              <Button
                type="submit"
                variant="contained"
                className={classes.submit}
              >
                Skapa &nbsp;
                {this.props.auth.isLoading &&  <Spinner color="#727981" size={12} /> }
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
});

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateUser: (request) => dispatch(updateUser(request, ownProps)),
    success: (request) => dispatch(success(request)),
    error: (request) => dispatch(error(request)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MyAccountContainer))
);
