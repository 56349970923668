import React from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import "moment/locale/sv";
import PictureAsPdf from '@material-ui/icons/PictureAsPdf'

const Documentlibrary = () => {

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={9}>
          <h1>Dokument</h1>
          </Grid>
          <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px'}}>     
                
          </Grid>
        </Grid>
      
        <Paper style={classes.paper}>
         
          <p>

<b>Egenkontrollprogram</b>

<p>
Genom att arbeta med egenkontroll arbetar vi med att förebygga hälsorisker. Egenkontrollprogrammet beskriver risker i verksamheten samt rutiner. 
</p>
<p>
För att vara säker på att viktiga arbetsmoment och kontroller genomförs är det viktigt att dokumentera enligt fastställda checklistor i EGNCTRL.</p>
          </p>
          
          <Grid container spacing={1}>
            <Grid item xs={4} lg={4}>
              <p><a href="/pdf/Faroanalys.pdf" target="_blank">Faroanalys</a></p>
              <p><a href="/pdf/Utbildning.pdf" target="_blank">Utbildning</a></p>
              <p><a href="/pdf/Personlighygien.pdf" target="_blank">Personlig hygien</a></p>
              <p><a href="/pdf/Inkop.pdf" target="_blank">Inköp</a></p>
              <p><a href="/pdf/Mottagningavvaror.pdf" target="_blank">Mottagning av varor</a></p>
              <p><a href="/pdf/Forvaring.pdf" target="_blank">Förvaring (torrt, kylt, fryst, varmt)</a></p>
            </Grid>
            <Grid item xs={4} lg={4}>
              <p><a href="/pdf/Matlagning.pdf" target="_blank">Matlagning</a></p>
              <p><a href="/pdf/Menyserveringochforsaljning.pdf" target="_blank">Meny, servering och försäljning</a></p>
              <p><a href="/pdf/Diskning.pdf" target="_blank">Diskning</a></p>
              <p><a href="/pdf/Stadningochrengoring.pdf" target="_blank">Städning och rengöring</a></p>
              <p><a href="/pdf/Avfall.pdf" target="_blank">Avfall</a></p>
              <p><a href="/pdf/Vatten.pdf" target="_blank">Vatten</a></p>
            </Grid>
            <Grid item xs={4} lg={4}>
              <p><a href="/pdf/Avlopp.pdf" target="_blank">Avlopp</a></p>
              <p><a href="/pdf/Skadedjur.pdf" target="_blank">Skadedjur</a></p>
              <p><a href="/pdf/Underhallavlokalerochutrustning.pdf" target="_blank">Underhåll av lokaler och utrustning</a></p>
              <p><a href="/pdf/Revision.pdf" target="_blank">Revision</a></p>
              <p><a href="/pdf/Provtagningochanalyser.pdf" target="_blank">Provtagning och analyser</a></p>
              <p><a href="/pdf/Reklamationerochklagomal.pdf" target="_blank">Reklamation och klagomål</a></p>
            </Grid>
          </Grid>

        </Paper>
       
      </Grid>
    
      
    </>
  );
};

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
  headline: { 
    color: "grey"
  }
};

const mapStateToProps = (state) => {
  return {
   
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documentlibrary);
