import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { listTask } from "../../store/actions/taskActions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import { list as listUnits } from "../../store/actions/unitActions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import DialogDefault from "../Shared/dialogDefault";
import Moment from "react-moment";
import "moment/locale/sv";
import TablePagination from "@material-ui/core/TablePagination";
import EditIcon from "@material-ui/icons/Edit";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const ListTaskContainer = (props) => {
  //const [] = React.useState("");
  const [unitId, setUnitId] = React.useState("");

  useEffect(() => {
    props.listTask(props.auth.user.customerId, null);
    props.listUnits();
  }, []);

  const handleChangeUnit = (event) => {
    props.listTask(props.auth.user.customerId, event.target.value);
    setUnitId(event.target.value);
  };

  const units = props.unit ? props.unit.list.data : [];
  const tasks = props.tasks ? _.orderBy(props.tasks.data, ["name"], ["asc"]) : [];

  const [dialog, setDialog] = useState({ open: false, id: null });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleClickCancel = () => {
    setDialog({
      open: false,
      id: null,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 50));
    setPage(0);
  };



  return (
    <Grid item xs={12} md={12} lg={12}>

      <Grid container spacing={1}>
        <Grid item xs={12} lg={9}>
          <h1>Arbetsuppgifter</h1>
        </Grid>
        <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'right', padding: '20px 0'}}>
        <Button variant="contained" component={Link} to="/task/create">
          <i className="gg-add"></i> Skapa ny uppgift
        </Button>
        </Grid>
      </Grid>

      <Paper style={classes.paper}>
      
      
       
        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
          <p>
            En arbetsuppgift är att utföra kontroll på en enhet som ingår i en
            kontrollpunkt. <br></br>
            Här ser du vilka arbetsuppgifter som finns upplagda och här kan du
            skapa nya arbetsuppgifter.
          </p>
          <h5>Vad betyder ikonerna?</h5>
   
          </Grid>

          {/* <Grid item xs={12} lg={3}>
          <small>
          <CheckCircleIcon
                            style={{ color: "green", fontSize: 20 }}
                          /> Uppgiften är flaggad som klar.
          </small>
          </Grid>

          <Grid item xs={12} lg={3}>
          
          
          <small>
          <VisibilityIcon
                            style={{ color: "grey", fontSize: 20 }}
                          /> Uppgiften har ej hanterats ännu.
          </small>
          </Grid>
          <Grid item xs={12} lg={3}>
          
          
          <small>
          <ReportProblemIcon
                            style={{ color: "red", fontSize: 20 }}
                          /> Uppgiften har delegeras för många gånger.
          </small>
<br></br>
          <small>
          <ReportProblemIcon
                            style={{ color: "orange", fontSize: 20 }}
                          /> Uppgiften är flaggad med en avvikelse (rapport).
          </small>
          </Grid> */}

          <Grid item xs={12} lg={3} style={{alignItems : 'center', justifyContent : 'center', top: '50%', textAlign: 'center', padding: '20px 0'}}>
          <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              //value={unitId}
              onChange={handleChangeUnit}
              defaultValue="2000"
              input={<BootstrapInput />}
            >
              <MenuItem value="2000">
                <em>Filtrera på enhet</em>
              </MenuItem>
              <MenuItem value="">
                <em>Alla</em>
              </MenuItem>
              {units.map((row) => (
                <MenuItem value={row.id}>{row.name}</MenuItem>
              ))}
            </Select>
        </Grid>
      </Grid>
      

        <TableContainer style={{ padding: 0 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Id</TableCell> */}
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Namn på uppgiften
                </TableCell>
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Tilldelad
                </TableCell>
                {/* <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Skapad
                </TableCell> */}
                <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Delegerad
                </TableCell>
                {/* <TableCell style={{ fontWeight: 900, fontSize: 10 }}>
                  Status
                </TableCell> */}
                <TableCell
                  style={{ fontWeight: 900, fontSize: 10 }}
                ></TableCell>
                <TableCell
                  style={{ fontWeight: 900, fontSize: 10 }}
                ></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    {/* <TableCell>{row.id}</TableCell> */}
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>{row.displayName}</TableCell>
                    {/* <TableCell>
                     <Moment locale="sv" fromNow format={"MMMM Do YYYY"}>
                        {row.createdOn && row.createdOn.toDate()}
                      </Moment>, <Moment locale="sv" fromNow>{row.createdOn && row.createdOn.toDate()}</Moment>.
                    </TableCell> */}
                    <TableCell>{row.delegatedTimes}</TableCell>
                    {/* <TableCell>
                      {row.completedState === "Klar" && (
                        <CheckCircleIcon
                          style={{ color: "green", fontSize: 30 }}
                        />
                      )}
                      {row.completedState === "" && (
                        <VisibilityIcon
                          style={{ color: "grey", fontSize: 30 }}
                        />
                      )}
                      {row.completedState === false && (
                        <VisibilityIcon
                          style={{ color: "grey", fontSize: 30 }}
                        />
                      )}
                      {row.completedState === "Avvikelse" && (
                        <ReportProblemIcon
                          style={{ color: "orange", fontSize: 30 }}
                        />
                      )}

                      {row.delegatedTimes > 2 && (
                        <ReportProblemIcon
                          style={{ color: "red", fontSize: 30 }}
                        />
                      )}
                    </TableCell> */}
                    <TableCell>{row.taskAdminName}</TableCell>
                    
                    <TableCell>
                      <Button
                        variant="contained"
                        component={Link} size="small"
                        to={"/task/edit/" + row.id}
                      >
                        Redigera
                      </Button>
                      </TableCell>
                      <TableCell>
                     
                      <Button size="small"
                        variant="contained"
                        onClick={() => {
                          setDialog({ open: true, id: row.id });
                        }}
                      >
                        Radera
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Rader per sida"
          rowsPerPageOptions={[50, 75, 100]}
          component="div"
          count={tasks.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      <DialogDefault
        module="task"
        action="delete"
        open={dialog.open}
        id={dialog.id}
        extraProps={{ unitId: unitId }}
        title={"Radera uppgift"}
        message={"Vill du verkligen redera denna uppgift?"}
        handleClickCancel={handleClickCancel}
      />
    </Grid>
  );
};

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    tasks: state.task.list,
    unit: state.unit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listTask: (customerId, unitId) => dispatch(listTask(customerId, unitId)),
    listUnits: () => dispatch(listUnits()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTaskContainer);
