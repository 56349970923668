import "firebase/auth";
import "firebase/firestore";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store/store.js";

import "./assets/css/egnctrl.css"

console.log("egnctrl env:", process.env.NODE_ENV);
console.log("egnctrl:", process.env.REACT_APP_ENV);

// // connect our app to firebase
// const dbConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
// }
// Firebase.initializeApp(dbConfig)

// Google Analytics
// https://github.com/react-ga/react-ga#api
//ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

// Sentry
// https://docs.sentry.io/clients/javascript/integrations/react/
//window.Raven.config(process.env.REACT_APP_SENTRY_RAVEN_TRACKING_URL).install();

// render the App component to our document root with React
// ReactDOM.render(<App />, document.getElementById('root'))

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <App />        
    </Provider>,
    document.getElementById("root")
  );
  //console.log(store);
  //registerServiceWorker();
});
