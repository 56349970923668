const initialState = {
  countryRegion: {
    isLoading: false,
    data: []
  }
};

const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COUNTRY_REGION_BEGIN":
      return {
        ...state,
        countryRegion: {
          data: [],
          isLoading: true
        }
      };
      case "FETCH_COUNTRY_REGION_SUCCESS":
      return {
        ...state,
        countryRegion: {
          data: action.payload,
          isLoading: false
        }
      };
    default:
      return state;
  }
};

export default masterDataReducer;
