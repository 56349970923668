import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash/core";
import EditNavigationSettings from "./EditNotificationSettings";

import { getById } from "../../store/actions/notificationSettingsActions";

const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class EditNotificationSettingsContainer extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    this.props.getById(id);
  };

  render() {
    const notificationSettings = this.props.edit;

    if (_.isEmpty(notificationSettings.data)) {
      return (
        <>
          
        </>
      );
    }

    return ( 
    <EditNavigationSettings
            notificationSettings={notificationSettings.data}>
    </EditNavigationSettings>
         
    );
  }
}

const mapStateToProps = state => {
  return {
    edit: state.notificationSettings.edit
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getById: id => dispatch(getById(id))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EditNotificationSettingsContainer))
);
