import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import SignInContainer from "./components/Auth/SignInContainer";
import ForgotPasswordContainer from "./components/Auth/ForgotPasswordContainer";
import CreateCustomerContainer from "./components/Customer/CreateCustomerContainer";
import EditCustomerContainer from "./components/Customer/EditCustomerContainer";
import ListCustomerContainer from "./components/Customer/ListCustomerContainer";
import CreateTaskAdminContainer from "./components/Task/CreateTaskAdminContainer";
import ListTaskAdminContainer from "./components/Task/ListTaskAdminContainer";
import CreateTaskContainer from "./components/Task/CreateTaskContainer";
import ListTaskContainer from "./components/Task/ListTaskContainer";
import EditTaskContainer from "./components/Task/EditTaskContainer";
import CreateUnitContainer from "./components/Unit/CreateUnitContainer";
import ListUnitContainer from "./components/Unit/ListUnitContainer";
import EditUnitContainer from "./components/Unit/EditUnitContainer";
import CreateControlRuleContainer from "./components/ControlRule/CreateControlRuleContainer";
import ListControlRuleContainer from "./components/ControlRule/ListControlRuleContainer";
import EditControlRuleContainer from "./components/ControlRule/EditControlRuleContainer";
import HomeContainer from "./components/HomeContainer";
import ErrorContainer from "./components/ErrorContainer";
import Reports from "./components/Reports/Reports"
import CreateNotificationSettingsContainer from "./components/NotificationSettings/CreateNotificationSettingsContainer";
import ListNotificationSettingsContainer from "./components/NotificationSettings/ListNotificationSettingsContainer";
import EditNotificationSettingsContainer from "./components/NotificationSettings/EditNotificationSettingsContainer";
import MyAccountContainer from "./components/Auth/MyAccountContainer";
import Documentlibrary from "./components/Documentlibrary/Documentlibrary";
import NotificationTest from "./components/NotificationTest/NotificationTest";

const Routes = props => {
  //console.log("props.auth.user.role", props.auth.user.role);

  return (
    <Switch>
      <Route exact path="/" component={HomeContainer} />
      <Route exact path="/signin" component={SignInContainer} />
      <Route exact path="/forgotpassword" component={ForgotPasswordContainer} />
      <Route exact path="/myaccount"
        render={renderProps =>
          props.auth.user.role ? (
              <MyAccountContainer {...renderProps} />
            ) : (
              <Redirect to="/signin" />
            )
          } 
      />
     
      <Route
        exact
        path="/customer/create"
        render={renderProps =>
          props.auth.user.role === "SuperAdmin" ? (
            <CreateCustomerContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />

      <Route 
        exact 
        path="/notifications/list" 
        render={renderProps =>
          props.auth.user.role === "SuperAdmin" ? (
            <NotificationTest {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      
      />  

      <Route 
        exact 
        path="/customer/list" 
        render={renderProps =>
          props.auth.user.role === "SuperAdmin" ? (
            <ListCustomerContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      
      />

      <Route
        exact
        path="/customer/edit/:id"
        render={renderProps =>
          props.auth.user.role === "SuperAdmin" ? (
            <EditCustomerContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/taskAdmin/create"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <CreateTaskAdminContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/taskAdmin/list"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <ListTaskAdminContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      {/* <Route
        exact
        path="/taskAdmin/edit/:id"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <EditTaskAdminContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      /> */}
      <Route
        exact
        path="/task/create"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <CreateTaskContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/task/list/:id"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <ListTaskContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/task/edit/:id"
    
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <EditTaskContainer {...renderProps} />
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/unit/create"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <CreateUnitContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/unit/list"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <ListUnitContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/unit/edit/:id"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <EditUnitContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/controlRule/create"
        render={renderProps =>
          (props.auth.user.role === "CustomerAdmin" || props.auth.user.role === "SuperAdmin" )? (
            <CreateControlRuleContainer {...renderProps} />
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/controlRule/list"
        render={renderProps =>
          (props.auth.user.role === "CustomerAdmin" || props.auth.user.role === "SuperAdmin" )? (
            <ListControlRuleContainer {...renderProps} />
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/controlRule/edit/:id"
        render={renderProps =>
        (props.auth.user.role === "CustomerAdmin" || props.auth.user.role === "SuperAdmin" )? (
            <EditControlRuleContainer {...renderProps} />
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route 
        exact path="/report" 
        render={renderProps =>
          props.auth.user.role ? (
              <Reports {...renderProps} />
            ) : (
              <Redirect to="/signin" />
            )
          } 
      />

      <Route 
        exact path="/docs" 
        render={renderProps =>
          props.auth.user.role ? (
              <Documentlibrary {...renderProps} />
            ) : (
              <Redirect to="/signin" />
            )
          } 
      />

      <Route
        exact
        path="/notificationSettings/create"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <CreateNotificationSettingsContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route
        exact
        path="/notificationSettings/list"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <ListNotificationSettingsContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />

      <Route
        exact
        path="/notificationSettings/edit/:id"
        render={renderProps =>
          props.auth.user.role === "CustomerAdmin" ? (
            <EditNotificationSettingsContainer {...renderProps}/>
          ) : (
            <Redirect to="/signin" />
          )
        }
      />
      <Route exact component={ErrorContainer} />
    </Switch>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    firebaseAuth: state.firebase.auth
  };
};

export default connect(mapStateToProps, null)(Routes);
