import moment from "moment";

export const createTask = (request, ownProps) => {

  return (dispatch, getState, { getFirestore }) => {
    
    console.log("request.startDate", request.startDate)

    const time = '07:00:00 AM';
    const d = moment(request.startDate).format('yyyy/MM/DD')
    const dateStart = moment(d +' '+ time).format()
    const jsDate = moment(dateStart).toDate()
    const firestore = getFirestore()
    const auth = getState().auth
    firestore
      .collection("tasks")
      .add({
        ...request,
        customerId: auth.user.customerId,
        createdOn: new Date(),
        createdBy: auth.user.uid,
        overDelegated: false,
        completedState: false,
        delegatedTimes: 0,
        startDate: jsDate
      })
      .then(function(response) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Uppgift skapad."
        });
        ownProps.history.push("/task/list/" + auth.user.customerId)
      })
      .catch(error => {
        console.log("errors==> " + error.message)
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Create Task failed!"
        });
        dispatch({ type: "CREATE_TASK_FAILED" })
      })

      let userToNotificate = firestore.collection("users").doc(request.taskAdminId)
      userToNotificate.get().then(function(doc) {

        if (doc.exists) {
          
          let today = moment()
          var beginningDateObject = new Date(request.startDate)
          let start = new Date(beginningDateObject).getTime() / 1000
          let rightnow = new Date().getTime() / 1000

          if(rightnow >= start){
           
            let tomorrow = moment(today)
            let todayPlusOneDay = new Date(tomorrow);

            let queryBadge = firestore.collection("tasks")     
            .where('taskAdminId', '==', request.taskAdminId)
            .where('customerId', '==', auth.user.customerId)
            .where("completedState", '==', false)
            .where('endDate', '>=', todayPlusOneDay)

            queryBadge.onSnapshot(function(querySnapshotBadge) {
            
                let item = 0
                querySnapshotBadge.forEach(function(bagdeDoc) {
                  let badgeValue = bagdeDoc.data()
                  if(rightnow > badgeValue.startDate.seconds){
                    item = item + 1
                  }
                })
                
                let data = doc.data()
                let token = data.notificationToken
        
                const message = {
                  to: token,
                  sound: 'default',
                  title: 'Du har tilldelats en uppgift',
                  body: 'Klicka här för att komma till EGNCTRL',
                  data: { data: 'goes here' },
                  _displayInForeground: true,
                  badge: item
                }
                
                if(request.repeatId == 1){
                  
                  const response = fetch('https://exp.host/--/api/v2/push/send', {
                    mode: 'no-cors',
                    method: 'POST',
                    headers: {
                      Accept: 'application/json',
                      'Accept-encoding': 'gzip, deflate',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(message),
                  })

                }

               

            })
          }
        } 
        else {     
        }
        }).catch(function(error) {
      })
  }
}



export const updateTask = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    
    dispatch({ type: "UPDATE_TASK_BEGIN" })
    const firestore = getFirestore()
    const auth = getState().auth
    let { id, ...task } = getState().task.edit.data
    Object.assign(task, request)
    task.updatedOn = new Date()
    task.customerId = auth.user.customerId
    task.updatedBy = auth.user.uid

    const time = '07:00 AM';
    const d = moment(request.startDate).format('yyyy/MM/DD')
    const dateStart = moment(d +' '+ time).format()
    const jsDate = moment(dateStart).toDate();
    
    task.startDate = jsDate
    
    // Reset the task if upated and overDelegated
    
    const taskIsOverDelegated = task.overDelegated
    if(taskIsOverDelegated){
      task.overDelegated = false
      task.delegatedTimes = 0
    }

    task.completedState = false
   
    firestore
      .collection("tasks")
      .doc(id)
      .update(task)
      .then(function(doc) {
        dispatch({ type: "UPDATE_TASK_SUCCESS" });
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Arbetsuppgift uppdaterad!"
        });

        dispatch({
          type: "UPDATE_TASK_SUCCESS",
          payload: null
        });

        ownProps.history.push("/task/list/" + auth.user.customerId);
      })
      .catch(function(error) {
        console.log("Fel: ", error);
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
      });
  };
};

export const remove = (id, unitId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;
    firestore
      .collection("tasks")
      .doc(id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          firestore
            .collection("tasks")
            .doc(id)
            .delete()
            .then(function(doc) {
              dispatch({
                type: "NOTIFICATION_SUCCESS",
                payload: "Raderad!"
              });

              dispatch({ type: "REMOVE_TASK_SUCCESS" });
             
              dispatch(listTask(auth.user.customerId, unitId));
            })
            .catch(function(error) {
              console.log("Fel raderad: ", error);
              dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
            });
        }
      });
  };
};

export const listTask = (customerId, unitId) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const auth = getState().auth
    const firestore = getFirestore();
    let tasks = [];
    try{

      dispatch({
        type: "UPDATE_TASK_SUCCESS",
        payload: null
      });

      let query = firestore.collection("tasks")     
      // query = query.where('customerId', '==', customerId)
     
      if(unitId){
        console.log("unitId", unitId)
        query = query.where('unitId', '==', unitId)
      }
      query = query.where('customerId', '==', auth.user.customerId)       
      query.get()
      .then(querySnapshot => {
      // uery.onSnapshot(function(querySnapshot) {        
        querySnapshot.forEach(function(doc) {        
            tasks.push({
              id: doc.id,
              ...doc.data()
            });        
        })

        tasks = tasks.sort((a, b) => b.name - a.name)
        dispatch({ type: "FETCH_TASKS_SUCCESS", payload: tasks })
        
    })

    }catch{
      dispatch({ type: "FETCH_TASK_FAILED", data: [] });
    }
   
      // .then(function(querySnapshot) {
      //   let tasks = [];
      //   querySnapshot.forEach(function(doc) {

      //     if(!uid || doc.data().taskAdminId==uid){
      //       tasks.push({
      //         id: doc.id,
      //         ...doc.data()
      //       });
      //     }
      //   });
      //   dispatch({ type: "FETCH_TASKS_SUCCESS", payload: tasks });
      // })
      // .catch(function(error) {
      //   console.log("Error getting task admins: ", error);
      //   dispatch({ type: "FETCH_TASK_FAILED", data: [] });
      // });
  };
};

export const getTaskById = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "FETCH_TASK_BEGIN", payload: null });
    const firestore = getFirestore();

    firestore
      .collection("tasks")
      .doc(request)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          dispatch({
            type: "FETCH_TASK_SUCCESS",
            payload: { id: doc.id, ...doc.data() }
          });
        } else {
          dispatch({ type: "FETCH_TASK_FAILED", payload: {} });
        }
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
        dispatch({ type: "FETCH_CUSTOMER_FAILED", payload: [] });
      });
  };
};

