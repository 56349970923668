export const create = (request, ownProps) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;

    firestore
      .collection("controlRules")
      .add({
        ...request,
        customerId: auth.user.customerId,
        createdOn: new Date(),
        createdBy: auth.user.uid
      })
      .then(function(response) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Kontrollpunkt skapad!"
        });

        dispatch({
          type: "CREATE_CONTROL-RULE_SUCCESS"
        });

        ownProps.history.push("/controlRule/list");
      })
      .catch(error => {
        console.log("errors==> " + error.message);
        dispatch({
          type: "NOTIFICATION_FAILED",
          payload: "Kontrollpunkt skapad ej!"
        });
      });
  };
};

export const getById = (id, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log("id", id);
    const firestore = getFirestore();
    firestore
      .collection("controlRules")
      .doc(id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          dispatch({
            type: "FETCH_CONTROL-RULE_SUCCESS",
            payload: { id: doc.id, ...doc.data() }
          });
        } else {
          dispatch({ type: "FETCH_CONTROL-RULE_FAILED", payload: {} });
        }
      })
      .catch(function(error) {
        console.log("Error getting Control rule: ", error);
        dispatch({ type: "FETCH_CONTROL-RULE_FAILED", payload: [] });
      });
  };
};

export const update = (request, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().firebase.auth;
    let { id, ...unit } = getState().controlRule.edit.data;
    Object.assign(unit, request);
    unit.updatedOn = new Date();
    unit.updatedBy = auth.uid;

    firestore
      .collection("controlRules")
      .doc(id)
      .update(unit)
      .then(function(doc) {
        dispatch({
          type: "NOTIFICATION_SUCCESS",
          payload: "Kontrollpunkt uppdaterad!"
        });

        dispatch({ type: "UPDATE_CONTROL-RULE_SUCCESS" });

        ownProps.history.push("/controlRule/list");
      })
      .catch(function(error) {
        console.log("Error Control rule update: ", error);
        dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
      });
  };
};

export const remove = (id, ownProps) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection("controlRules")
      .doc(id)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          firestore
            .collection("controlRules")
            .doc(id)
            .delete()
            .then(function(doc) {
              dispatch({
                type: "NOTIFICATION_SUCCESS",
                payload: "Kontrollpunkt raderad!"
              });

              dispatch({ type: "REMOVE_CONTROL-RULE_SUCCESS" });

              dispatch(list());
            })
            .catch(function(error) {
              console.log("Error Control Rule delete: ", error);
              dispatch({ type: "NOTIFICATION_FAILED", payload: error.message });
            });
        }
      });
  };
};

export const list = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;

    firestore
      .collection("controlRules")
      .orderBy("name", 'asc')
      .get()
      .then(function(querySnapshot) {
        let controlRules = [];
        querySnapshot.forEach(function(doc) {
          let controlRule = doc.data();
          //debugger;

          if(auth.user.role === "SuperAdmin"){
            if(controlRule.customerId === null ){
              controlRules.push({
                id: doc.id,
                ...doc.data()
              });
            }
          }
          else{

            console.log("auth", auth.user)  

            if(auth.user.customerType === "1"){
              if(controlRule.controlType === "1" ){
                controlRules.push({
                  id: doc.id,
                  ...doc.data()
                });
              }
            }
            else{
              if(
                controlRule.customerId === null && controlRule.controlType !== "1" || 
                controlRule.customerId === auth.user.customerId && controlRule.controlType !== "1" 
                ){
                controlRules.push({
                  id: doc.id,
                  ...doc.data()
                });
              }
            }
            
          }
         
        });
        dispatch({ type: "FETCH_CONTROL-RULES_SUCCESS", payload: controlRules });
      })
      .catch(function(error) {
        console.log("Error getting control rules : ", error);
        dispatch({ type: "FETCH_CONTROL-RULES_FAILED", data: [] });
      });
  };
};


export const listMine = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const auth = getState().auth;

    firestore
      .collection("controlRules")
      .orderBy("name", 'asc')
      .get()
      .then(function(querySnapshot) {
        let controlRules = [];
        querySnapshot.forEach(function(doc) {
          let controlRule = doc.data();
          //debugger;
          if(controlRule.customerId === null || controlRule.customerId === auth.user.customerId){
            controlRules.push({
              id: doc.id,
              ...doc.data()
            });
          }
          
         
        });
        dispatch({ type: "FETCH_CONTROL-RULES_SUCCESS", payload: controlRules });
      })
      .catch(function(error) {
        console.log("Error getting control rules : ", error);
        dispatch({ type: "FETCH_CONTROL-RULES_FAILED", data: [] });
      });
  };
};
