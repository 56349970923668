import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash/core";
import { update as updateNotificationSettings } from "../../store/actions/notificationSettingsActions";
import { success, error } from "../../store/actions/toasterActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import moment from "moment"
import MomentUtils from "@date-io/moment"
import svLocale from "date-fns/locale/sv"
import DateFnsUtils from "@date-io/date-fns"; // import
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

const localeMap = {
  sv: svLocale,
};


const styles = theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  paper: {
    paddingTop: '1px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    color: "black",
  },
});

class EditNotificationSettings extends Component {
  constructor(props) {
    super(props);

    console.log("EditNotificationSettingsContainer=>props", this.props);
    this.state = {
      name: {
        displayName: "Name",
        value: this.props.notificationSettings.name,
        required: true
      },
      fromDate: {
        displayName: "From Date",
        value: this.props.notificationSettings.fromDate,
        required: true
      },
      toDate: {
        displayName: "To Date",
        value: this.props.notificationSettings.toDate,
        required: true
      }
      
    }

    this.handleCalStartChange = this.handleCalStartChange.bind(this)
    this.handleCalEndChange = this.handleCalEndChange.bind(this)


  }

  componentDidMount(){
    this.setState({
      "fromDate": this.props.notificationSettings.fromDate,
    })   
    this.setState({
      "toDate": this.props.notificationSettings.toDate,
    })   
  }

  handleChange = e => {
    let currentField = this.state[e.target.name];
    if (currentField) {
      currentField.value = e.target.value;

      this.setState({
        [e.target.name]: currentField
      });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    const request = this.validateInput();
    if (!_.isEmpty(request)) {
      this.props.updateNotificationSettings(request);
    }
  };

  validateInput = () => {
    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function(value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false;
      } else {
        if(key == "name"){
          request[key] = value.value;
        }else{
          request[key] = value;
        }
      }
    });

    return isValid ? request : {};
  };
  
  handleCalStartChange(e)  {
    console.log("eeeee", e)
    this.setState({
      "fromDate": e,
    })   
  }

  handleCalEndChange(e)  {
    this.setState({
      "toDate": e,
    })   
  }

  toDateTime(secs) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    return t;
  }

  render() {
    const { classes } = this.props;
    const notificationSettings = this.props.notificationSettings;

    console.log("this.state.fromDate", this.state.fromDate)

    let fromDate
    if(this.state.fromDate.seconds){
      fromDate = this.toDateTime(this.state.fromDate.seconds)
    }
    else{
      fromDate = this.state.fromDate
    }

    let toDate
    if(this.state.toDate.seconds){
      toDate = this.toDateTime(this.state.toDate.seconds)
    }
    else{
      toDate = this.state.toDate
    }

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
           
            <h1>Uppdatera kalenderavvikelse</h1>
     
            <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                    
                        <h3>Detaljer</h3>
                        <p>Ange inom det två datum plus/minus en dag som du vill att din kalenderavvikelse ska gälla.</p>
                        <TextField
                          key={notificationSettings.name}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          id="name"
                          name="name"
                          label="Namn"
                          autoComplete="name"
                          autoFocus
                          defaultValue={notificationSettings.name}
                          onChange={this.handleChange}
                        />

                    </Grid>
                    <Grid item xs={12} lg={4}>

                        <h3>Från datum:</h3>
                        <MuiPickersUtilsProvider 
                        libInstance={moment} 
                        utils={DateFnsUtils} 
                        locale={localeMap['sv']}>

                          <DatePicker id="fromDate" 
                          disablePast={false}
                          format="yyyy-MMM-d"           
                          name="fromDate" value={fromDate}
                          onChange={this.handleCalStartChange} />

                        </MuiPickersUtilsProvider>            

                    </Grid>
                
                    <Grid item xs={12} lg={4}>
                      
                      <h3>Till datum:</h3>
                      <MuiPickersUtilsProvider 
                        libInstance={moment} 
                        utils={DateFnsUtils} 
                        locale={localeMap['sv']}>

                          <DatePicker id="toDate" 
                          disablePast={false}
                          format="yyyy-MMM-d"                         
                          name="toDate" value={toDate}
                          onChange={this.handleCalEndChange} />

                        </MuiPickersUtilsProvider>   

                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Button
                          type="submit"
                          variant="contained"                       
                          className={classes.submit}
                        >
                          Spara
                        </Button>
                    
                    </Grid>
                </Grid>

              {/* <Grid item xs={12} lg={6}>
              
                  <h3>Detaljer</h3>

                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="name"
                    name="name"
                    label="Namn"
                    autoComplete="name"
                    autoFocus
                    onChange={this.handleChange}
                    defaultValue={notificationSettings.name}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="fromDate"
                    name="fromDate"
                    label="From Date"
                    type="date"
                    onChange={this.handleChange}
                    defaultValue={notificationSettings.fromDate}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="toDate"
                    name="toDate"
                    label="To Date"
                    type="date"
                    onChange={this.handleChange}
                    defaultValue={notificationSettings.toDate}
                  />

                  <Button
                    type="submit"
                    variant="contained"                   
                    className={classes.submit}
                  >
                    Uppdatera
                  </Button>
                
              </Grid> */}
          
          </form>
          </Paper>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateNotificationSettings: request =>
    dispatch(updateNotificationSettings(request, ownProps)),
    success: request => dispatch(success(request)),
    error: request => dispatch(error(request))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(styles)(EditNotificationSettings))
);
