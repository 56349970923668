import _ from "lodash/core";

const countryRegion = require("../data/country-region.json");

export const getCountryList = () => {
  let countryList = [];
  _.forEach(countryRegion, function (country) {
    countryList.push({
      name: country.countryName.trim(),
      code: country.countryShortCode.trim(),
    });
  });

  return countryList;
};

export const getRegionList = (countryName) => {
  const regionList = _.filter(countryRegion, { countryName: countryName });
  if (regionList && regionList.length > 0) {
    return regionList[0].regions;
  }
  return [];
};

export const getBadgeCount = (reportCount) => {
  let badgeCount = 0;
  try {
    const localReportCount = localStorage.getItem("reportCount");
    if ((!localReportCount || localReportCount == "undefined") && reportCount > 0) {
      badgeCount = 1;
    } else if (reportCount > localReportCount) {
      badgeCount = 1;
    }
  } catch (error) {
    console.log(error);
  }
  return badgeCount;
};

export const setLocalBadgeCount = async (reportCount) => {
  try {
    localStorage.setItem("reportCount", reportCount);
  } catch (error) {
    console.log(error);
  }
};
