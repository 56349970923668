import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { create } from "../../store/actions/customerActions";
import { success, error } from "../../store/actions/toasterActions";
import { getCountryList, getRegionList } from "../../helpers/masterDataHelper";
import _ from "lodash/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Spinner } from 'react-activity';

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
});

class CreateCustomerContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: {
        displayName: "Företagsnamn",
        value: null,
        required: true,
      },
      email: {
        displayName: "Email",
        value: null,
        required: true,
      },
      password: {
        displayName: "Lösenord",
        value: null,
        required: true,
      },
      address: {
        displayName: "Address",
        value: null,
        required: true,
      },
      zip: {
        displayName: "Postnummer",
        value: null,
        required: true,
      },
      // country: {
      //   displayName: "Country",
      //   value: null,
      //   required: true,
      // },
      // city: {
      //   displayName: "City",
      //   value: null,
      //   required: true,
      // },
      organizationalNumber: {
        displayName: "Org nummer",
        value: null,
        required: true,
      },
      subscriptionValidUntil: {
        displayName: "Subscription Valid Until",
        value: null,
        required: true,
      },
      licenseType: {
        displayName: "Licens typ",
        value: null,
        required: true,
      },
      namecontact:{
        displayName: "Kontakt namn",
        value: null,
        required: true,
      },

      altemail:{
        displayName: "Email för rapportering",
        value: null,
        required: true,
      },
      
      tel:{
        displayName: "Kontakt namn",
        value: null,
        required: true,
      },
      county:{
        displayName: "Ort",
        value: null,
        required: false
      },
      customerNumber:{
        displayName: "Kundnummer",
        value: null,
        required: false
      }
      ,
      restaurantName:{
        displayName: "Namn på verksamheten",
        value: null,
        required: false
      },
      restaurantZip:{
        displayName: "Postadress",
        value: null,
        required: false
      },
      restaurantZipNumber:{
        displayName: "Postnummer/Ort",
        value: null,
        required: false
      },
      restaurantTel:{
        displayName: "Telefon",
        value: null,
        required: false
      },
      customerType:{
        displayName: "Typ av kund",
        value: null,
        required: false
      },

    };
    this.regionList = [];

    this.licenseType = [
      { key: "class1", value: "1-3 användare" },
      { key: "class2", value: "4-6 användare" },
      { key: "class3", value: "7-10 användare" },
      { key: "class4", value: "11-20 användare" },
    ];
  }

  handleDateChange = () => {
    // setSelectedDate(date);
  };

  handleChange = (e) => {
    let currentField = this.state[e.target.name];
    if (currentField) {
      currentField.value = e.target.value;

      this.setState({
        [e.target.name]: currentField,
      });

      if (e.target.name === "country") {
        this.regionList = getRegionList(e.target.value);
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const request = this.validateInput();
    if (!_.isEmpty(request)) {
      this.props.create(request);
      //this.props.history.push("/customer/list");
    }
  };

  validateInput = () => {
    let isValid = true;
    const _this = this;
    let request = {};
    _.forEach(this.state, function (value, key) {
      if (value.required && _.isEmpty(value.value)) {
        isValid = false;
        _this.props.error(`${value.displayName} är obligatorisk`);
        return false;
      } else {
        request[key] = value.value;
      }
    });

    return isValid ? request : {};
  };

  render() {
    const { classes } = this.props;
    const countryList = getCountryList();

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <h1>Skapa kund</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              turpis enim, scelerisque in nulla sed, ultrices luctus sapien.
              Donec non nulla in libero eleifend hendrerit nec id nibh. Nam
              convallis consequat venenatis. Nunc placerat, urna vel congue
              vulputate, enim risus pellentesque neque, eget molestie odio eros
              sed velit.{" "}
            </p>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Företags namn"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  onChange={this.handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  onChange={this.handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="password"
                  label="Lösenord"
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="address"
                  name="address"
                  label="Address"
                  onChange={this.handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="zip"
                  name="zip"
                  label="Postnummer"
                  onChange={this.handleChange}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="county"
                  name="county"
                  label="Ort"
                  onChange={this.handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="customerNumber"
                  name="customerNumber"
                  label="Kundnummer"
                  onChange={this.handleChange}
                />

              </Grid>

              <Grid item xs={6}>
                
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="namecontact"
                  name="namecontact"
                  label="Namn kontaktperson"
                  onChange={this.handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="altemail"
                  name="altemail"
                  label="Email för rapportering"
                  onChange={this.handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="tel"
                  name="tel"
                  label="Telefon nummer"
                  onChange={this.handleChange}
                />

                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="countryLabel">Land</InputLabel>
                  <Select
                    variant="outlined"
                    id="country"
                    name="country"
                    labelId="countryLabel"
                    label="Land"
                    labelWidth={55}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {countryList.map((country) => (
                      <MenuItem value={country.name} key={country.name}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                {/* <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="cityLabel">Stad</InputLabel>
                  <Select
                    variant="outlined"
                    id="city"
                    name="city"
                    labelId="cityLabel"
                    label="city"
                    labelWidth={30}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {this.regionList.map((region) => (
                      <MenuItem value={region.name} key={region.name}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="organizationalNumber"
                  name="organizationalNumber"
                  label="Org. nummer"
                  onChange={this.handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="subscriptionValidUntil"
                  name="subscriptionValidUntil"
                  label="Abonnemnag till och med"
                  autoComplete="subscriptionValidUntil"
                  type="date"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id=" licenseTypeLabel">Licens typ</InputLabel>
                  <Select
                    variant="outlined"
                    id="licenseType"
                    name="licenseType"
                    labelId="licenseTypeLabel"
                    label="licenseTypeLabel"
                    labelWidth={70}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
                    {this.licenseType.map((type) => (
                      <MenuItem value={type.key} key={type.key}>
                        {type.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>


                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantName"
                  name="restaurantName"
                  label="Namn på verksamheten"
                  onChange={this.handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantZip"
                  name="restaurantZip"
                  label="Postadress"
                  onChange={this.handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantZipNumber"
                  name="restaurantZipNumber"
                  label="Postnummer/Ort"
                  onChange={this.handleChange}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  //required
                  fullWidth
                  id="restaurantTel"
                  name="restaurantTel"
                  label="Tel."
                  onChange={this.handleChange}
                />    

                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id=" licenseTypeLabel">Typ av kund</InputLabel>
                  <Select
                    variant="outlined"
                    id="customerType"
                    name="customerType"
                    labelId="customerTypeLabel"
                    label="customerTypeLabel"
                    labelWidth={70}
                    defaultValue=""
                    onChange={this.handleChange}
                  >
               
                      <MenuItem value={'0'} key={'0'}>
                        Restaurang
                      </MenuItem>
                      <MenuItem value={'1'} key={'1'}>
                        Slakteri
                      </MenuItem>
                  </Select>
                </FormControl>


                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submit}
                >
                  Skapa kund
                  &nbsp;
                  {this.props.customer.edit.isLoading &&  <Spinner color="#fff" size={12} /> }   
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: state.customer
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    create: (request) => dispatch(create(request, ownProps)),
    success: (request) => dispatch(success(request)),
    error: (request) => dispatch(error(request)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CreateCustomerContainer))
);
