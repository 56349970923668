import React, { Component } from "react";
import { connect } from "react-redux";
import { sendForgotPassword } from "../../store/actions/authActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import { success, error } from "../../store/actions/toasterActions";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    paddingTop: "1px",
    paddingLeft: "20px",
    paddingBottom: "10px",
    color: "black",
  },
});

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      loading: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.validateInput()) {
      this.setState({ loading: true });

      try {
        await this.props.sendForgotPassword(this.state.email);
      } catch (err) {
        console.log(err);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  validateInput = () => {
    let isValid = true;
    const inputValues = this.state;

    if (!inputValues.email) {
      isValid = false;
      const _this = this;
      _this.props.error(`Email är obligatorisk`);
    }
    return isValid;
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <h1>Glömt lösenord</h1>
            <p>
              Om ditt email finns i EGNCTRL så kommer du få ett email för att återställa lösenordet.
            </p>

            <Grid item xs={12} lg={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={this.handleChange}
              />

              <br></br>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                <i class="gg-log-in"></i> Skicka &nbsp;
                {this.state.loading && <Spinner color="#fff" size={12} />}
              </Button>

             <br></br>
              <Link to="/signin" variant="body2">
                {'<<'} Tillbaka
              </Link>

              <Grid item xs>
                
              </Grid>

            </Grid>
          </form>
        </Paper>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    firebaseAuth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendForgotPassword: (email) =>
      dispatch(sendForgotPassword(email, ownProps)),
    success: (request) => dispatch(success(request)),
    error: (request) => dispatch(error(request)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForgotPasswordContainer));
