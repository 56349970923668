import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { getCountryRegionList } from "../../store/actions/masterDataActions";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Redirect, Link } from "react-router-dom";
import logo from "./logo.png";
import { Spinner } from 'react-activity';
import Grid from "@material-ui/core/Grid";
import 'react-activity/dist/react-activity.css';


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
});

class SignInContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };
  }

  componentDidMount = async () => {
    this.props.getCountryRegionList();
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    if (this.validateInput()) this.props.signIn(this.state);
  };

  validateInput = () => {
    let isValid = true;
    const inputValues = this.state;

    if (!inputValues.email) {
      isValid = false;
      alert("Email är obligatorisk");
    } else if (!inputValues.password) {
      isValid = false;
      alert("Lösenord är obligatorisk");
    }
    return isValid;
  };

  render() {

    //console.log("this.props.firebaseAuth", this.props.firebaseAuth.email)

    if (this.props.firebaseAuth.uid) return <Redirect to="/" />;
    const { classes } = this.props;
    //console.log("classes ==> " + JSON.stringify(classes));
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>

          <img alt='' src={logo}></img>
          <br></br>
          <br></br><br></br>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={this.handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Lösenord"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handleChange}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Kom ihåg mig"
            /> */}
            <br></br>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              <i class="gg-log-in"></i> Logga in  
              &nbsp;
              {this.props.auth.isLoading &&  <Spinner color="#fff" size={12} /> }
             
            </Button>
            {/* { <p>{this.props.authError}</p> } */}
            <Grid container>
              <Grid item xs>
                <Link to={"/forgotpassword"} variant="body2">
                  Glömt lösenord?
                </Link>
              </Grid>             
            </Grid>
          </form>
        </div>
        <Box mt={8}>{/* <Copyright /> */}</Box>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    firebaseAuth: state.firebase.auth,
    //authError: state.auth.authError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: credentials => dispatch(signIn(credentials)),
    getCountryRegionList: () => dispatch(getCountryRegionList())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SignInContainer));
//export default connect(mapStateToProps, mapDispatchToProps)(SignInContainer);
