import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import masterDataReducer from "./masterDataReducer";
import authReducer from "./authReducer";
import customerReducer from "./customerReducer";
import toasterReducer from "./toasterReducer";
import taskAdminReducer from "./taskAdminReducer";
import taskReducer from "./taskReducer";
import unitReducer from "./unitReducer";
import controlRuleReducer from "./controlRuleReducer";
import reportReducer from "./reportReducer";
import notificationSettingsReducer from "./notificationSettingsReducer";

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  toaster: toasterReducer,
  masterData: masterDataReducer,
  auth: authReducer,
  customer:customerReducer,
  taskAdmin:taskAdminReducer,
  task:taskReducer,
  unit:unitReducer,
  controlRule:controlRuleReducer,
  report:reportReducer,
  notificationSettings:notificationSettingsReducer,
});

export default rootReducer;
